import React, { useContext } from "react";
import { RequireAuth } from "react-auth-kit";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "pages/Login";
import RecoverPassword from "pages/RecoverPassword";
import NewPassword from "pages/NewPassword";
import NewAccountPassword from "pages/NewAccountPassword";
import SignUp from "pages/SignUp";
import Homepage from "pages/Homepage";
import TherapeuticArea from "pages/TherapeuticArea";
import TherapeuticAreaIndication from "pages/TherapeuticAreaIndication";
import Article from "pages/Article";
import Error500 from "pages/Error500";
import CheckHCMID from "components/login/CheckHCMID";
import Archive from "pages/Archive";
import Search from "pages/Search";
import appContext from "utils/context";
import { redirects } from "utils/api/redirects";

const RedirectHandler = () => {
  const location = useLocation();
  const newPath = redirects[location.pathname]; // Check if path needs redirection

  return newPath ? (
    <Navigate to={newPath} replace />
  ) : (
    <Navigate to="/login" replace />
  );
};

const RoutesComponent = ({ contentRef }) => {
  const { serverTime } = useContext(appContext);

  return (
    <BrowserRouter>
      {serverTime === 0 ? (
        <Routes>
          <Route path="/500" element={<Error500 />} />
          <Route path="*" element={<Navigate to="/500" replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/pages/*"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <RedirectHandler />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route
            path="/review"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <RedirectHandler />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route
            path="/webcast/*"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <RedirectHandler />
                </RequireAuth>
              </CheckHCMID>
            }
          />

          <Route path="/login" element={<Login contentRef={contentRef} />} />
          <Route
            path="/recover-password"
            element={<RecoverPassword contentRef={contentRef} />}
          />
          <Route
            path="/email/set-new-password"
            element={<NewPassword contentRef={contentRef} />}
          />
          <Route
            path="/access-request"
            element={<SignUp contentRef={contentRef} />}
          />
          <Route
            path="/new-password/:accessToken"
            element={<NewAccountPassword contentRef={contentRef} />}
          />
          <Route
            path="/therapeutic-areas/:therapeuticAreaSlug/:therapeuticAreaIndicationSlug/:contentSlug/:articleSlug/:tabSlug?"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <Article contentRef={contentRef} />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route
            path="/therapeutic-areas/:therapeuticAreaSlug/:therapeuticAreaIndicationSlug/:contentSlug?"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <TherapeuticAreaIndication contentRef={contentRef} />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route
            path="/therapeutic-areas/:therapeuticAreaSlug"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <TherapeuticArea contentRef={contentRef} />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route
            path="/archive/:archiveSlug/:editionSlug?/:episodeSlug?"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <Archive contentRef={contentRef} />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route
            path="/search/:tag"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <Search contentRef={contentRef} />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route
            path="/"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <Homepage />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default RoutesComponent;
