import React, { useState, useEffect } from "react";
import { useSignIn } from "react-auth-kit";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  TextField,
  Typography,
  Box,
  Stack,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import { FormErrorMessage, LinkText } from "components/common";
import theme from "utils/theme";
import config from "utils/config";
import {
  ArrowLongRightIcon,
  ArrowLongLeftIcon,
  EyeIcon,
  EyeSlashIcon,
  CheckCircleIcon,
  LockClosedIcon,
  ArrowPathIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";

const LoginForm = () => {
  const signIn = useSignIn();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentStep, setCurrentStep] = useState("check-email");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // ------------------------------------------------------------------------------------
  // CHECK EMAIL ADDRESS

  const handleSubmitStepCheckEmail = async (values) => {
    // console.log("VALUES", values);
    setLoading(true);

    try {
      const response = await axios.post(`${config.API_URL}/login`, {
        user_email: values.email,
      });

      if (response.data.message === "Proceed.") {
        formikUserCredentials.values.email = values.email;
        setError("");
        setCurrentStep("check-user-credentials");
      } else if (response.data.message === "No user found.") {
        setError(
          "Adresa de e-mail introdusă nu este în baza noastră de date. Asigurați-vă că adresa de e-mail este corect scrisă."
        );
        formikNewAccount.values.email = response.data.emailToRegister;
      } else if (response.data.message === "No Agreement.") {
        formikUserNoGDPR.values.email = values.email;
        setCurrentStep("no-gdpr");
      } else if (response.data.message === "Password not set.") {
        await resendActivationEmail();
        setCurrentStep("account-inactive");
      } else if (response.data.message === "User not active.") {
        await resendActivationEmail();
        setCurrentStep("account-inactive");
      } else {
        setError(config.ERRORS.API_ERROR_CHECK_EMAIL_ADDRESS);
      }
    } catch (err) {
      setError(config.ERRORS.API_ERROR_LOGIN_CREDENTIALS);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const formikCheckEmail = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Adresa de e-mail este obligatorie.")
        .email("Adresa introdusă nu pare a fi corectă."),
    }),
    onSubmit: handleSubmitStepCheckEmail,
    handleChange: () => {
      setError("");
    },
  });

  useEffect(() => {
    setError("");
  }, [formikCheckEmail.values.email]);

  // ------------------------------------------------------------------------------------
  // SIGN UP NEW ACCOUNT

  const handleSubmitStepNewAccount = async (values) => {
    // console.log("VALUES", values);
    navigate(`/access-request?email=${values.email}`);
  };

  const formikNewAccount = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Adresa de e-mail este obligatorie.")
        .email("Adresa introdusă nu pare a fi corectă."),
    }),
    onSubmit: handleSubmitStepNewAccount,
  });

  // ------------------------------------------------------------------------------------
  // CHECK USER CREDENTIALS

  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmitUserCredentials = async (values) => {
    // console.log("VALUES", values);
    setLoading(true);

    try {
      const response = await axios.post(`${config.API_URL}/login`, {
        user_email: values.email,
        user_password: values.password,
        remember_me: rememberMe,
      });

      if (response.data.message === "Login successful") {
        // GET USER PROFILE
        try {
          const responseUserProfile = await axios.get(
            `${config.API_URL}/get-profile-settings`,
            {
              headers: {
                Authorization: `Bearer ${response.data.token}`,
              },
            }
          );
          signIn({
            token: response.data.token,
            tokenType: "Bearer",
            authState: responseUserProfile.data,
            expiresIn: rememberMe ? 30 * 24 * 60 : 24 * 60, // minutes
          });
          navigate("/");
          // console.log(response);
        } catch (err) {
          setError(config.ERRORS.API_ERROR_LOGIN_CREDENTIALS);
          console.error(err);
        }
      } else if (response.data.message === "No user found.") {
        setError(
          "Adresa de e-mail introdusă nu este în baza noastră de date. Asigurați-vă că adresa de e-mail este corect scrisă."
        );
        formikNewAccount.values.email = formikCheckEmail.values.email;
        setCurrentStep("check-email");
      }
      setLoading(false);
    } catch (err) {
      // console.log("eroare login", err, JSON.stringify(err));
      // if (err.response.status === 401) {
      setError(
        "Parola introdusă nu este corectă. Vă rugăm să încercați din nou."
      );
      // } else {
      // setError(config.ERRORS.API_ERROR_LOGIN_CREDENTIALS);
      // }
    }
  };

  const formikUserCredentials = useFormik({
    initialValues: {
      email: "", //test@test.com
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Adresa de e-mail este obligatorie.")
        .email("Adresa introdusă nu pare a fi corectă."),
      password: Yup.string()
        .required("Parola este obligatorie.")
        .min(8, "Parola trebuie sa conțină minimum 8 caractere."),
    }),
    onSubmit: handleSubmitUserCredentials,
  });

  useEffect(() => {
    setError("");
    setLoading(false);
  }, [formikUserCredentials.values.password]);

  // ------------------------------------------------------------------------------------
  // USER NO GDPR ACCEPTANCE

  const handleSubmitUserNoGDPR = async (values) => {
    // console.log("VALUES", values);

    setLoading(true);
    try {
      const response = await axios.post(`${config.API_URL}/send-gdpr-email`, {
        user_email: values.email,
      });

      if (response.data.message === "GDPR Email to AZ was sent") {
        setCurrentStep("no-gdpr-message-sent");
      } else if (response.data.message === "No user found.") {
        setError(
          "Adresa de e-mail introdusă nu este în baza noastră de date. Asigurați-vă că adresa de e-mail este corect scrisă."
        );
      }
    } catch (err) {
      setError(config.ERRORS.API_ERROR_LOGIN_CREDENTIALS);
    } finally {
      setLoading(false);
    }
  };
  const formikUserNoGDPR = useFormik({
    initialValues: {
      email: "", //test@test.com
    },
    onSubmit: handleSubmitUserNoGDPR,
  });

  const goToStep1 = (resetEmail = false) => {
    if (resetEmail) {
      formikCheckEmail.values.email = "";
    }
    setError("");
    setCurrentStep("check-email");
  };

  // ------------------------------------------------------------------------------------
  // RESEND ACTIVATION EMAIL

  const [resendActivationEmailStatus, setResendActivationEmailStatus] =
    useState(null);
  const resendActivationEmail = async () => {
    // console.log("EMAIL", formikCheckEmail.values.email);
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.API_URL}/send-activation-email`,
        {
          user_email: formikCheckEmail.values.email,
        }
      );

      if (response.data.message === "Email activation sent successfully") {
        setResendActivationEmailStatus("ok");
      } else {
        setResendActivationEmailStatus("error");
      }
    } catch (err) {
      setResendActivationEmailStatus("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {[
        "check-email",
        "check-user-credentials",
        "no-gdpr",
        "no-gdpr-message-sent",
      ].includes(currentStep) && (
        <Box
          sx={{
            marginBottom: 8,
            textAlign: {
              xs: "center",
              md: "left",
            },
          }}
        >
          <Typography variant="h1">Bine ați venit</Typography>
          {(currentStep === "check-email" ||
            currentStep === "check-user-credentials") && (
            <Typography>
              Pentru a vă conecta este necesar un cont activ.
            </Typography>
          )}
        </Box>
      )}
      {currentStep === "check-email" && (
        <>
          <form onSubmit={formikCheckEmail.handleSubmit}>
            <Stack
              alignItems="flex-start"
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: {
                  xs: 3,
                  sm: 0,
                },
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  width: "100%",
                }}
              >
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Adresa de e-mail"
                  variant="filled"
                  value={formikCheckEmail.values.email}
                  onChange={formikCheckEmail.handleChange}
                  onBlur={formikCheckEmail.handleBlur}
                  error={
                    formikCheckEmail.touched.email &&
                    Boolean(formikCheckEmail.errors.email)
                  }
                  helperText={
                    formikCheckEmail.touched.email &&
                    formikCheckEmail.errors.email
                  }
                  sx={{
                    "& input": {
                      padding: "0.75rem 4.25rem 0.25rem 1rem",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: {
                    xs: 1,
                    sm: 0,
                  },
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                  marginLeft: {
                    xs: 0,
                    sm: -7.5,
                  },
                }}
              >
                <Button
                  disabled={loading}
                  variant="contained"
                  fullWidth
                  size="large"
                  type="submit"
                >
                  Continuă
                </Button>
              </Box>
            </Stack>
          </form>
          {error === "" ? (
            <>
              {/* <Box marginTop={5}>
                <Typography>
                  Nu aveți cont?{" "}
                  <LinkText to="/access-request">Solicitați acces</LinkText>
                </Typography>
              </Box> */}
            </>
          ) : (
            <>
              <FormErrorMessage>{error}</FormErrorMessage>
              <Box marginTop={5}>
                <Typography>Nu aveți cont?</Typography>
                <Typography>
                  Pentru a primi acces în portalul AstraZeneca România, vă rugăm
                  să completați formularul de creare cont.
                </Typography>
                <form onSubmit={formikNewAccount.handleSubmit}>
                  <Stack
                    alignItems="flex-start"
                    sx={{
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                      gap: {
                        xs: 3,
                        sm: 0,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        width: "100%",
                      }}
                    >
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Adresa de e-mail"
                        variant="filled"
                        value={formikNewAccount.values.email}
                        onChange={formikNewAccount.handleChange}
                        onBlur={formikNewAccount.handleBlur}
                        error={
                          formikNewAccount.touched.email &&
                          Boolean(formikNewAccount.errors.email)
                        }
                        helperText={
                          formikNewAccount.touched.email &&
                          formikNewAccount.errors.email
                        }
                        sx={{
                          "& input": {
                            padding: "0.75rem 4.25rem 0.25rem 1rem",
                          },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        flexGrow: {
                          xs: 1,
                          sm: 0,
                        },
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                        marginLeft: {
                          xs: 0,
                          sm: -7.5,
                        },
                      }}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        type="submit"
                        endIcon={<ArrowLongRightIcon width={"1.25rem"} />}
                      >
                        Formular
                      </Button>
                    </Box>
                  </Stack>
                </form>
              </Box>
            </>
          )}
        </>
      )}
      {currentStep === "check-user-credentials" && (
        <>
          <Box
            sx={{
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <Button
              variant="text"
              startIcon={<ArrowLongLeftIcon width={20} />}
              onClick={() => goToStep1()}
              sx={{ marginBottom: 2.5 }}
            >
              Înapoi
            </Button>
          </Box>
          <form onSubmit={formikUserCredentials.handleSubmit}>
            <FormControl
              fullWidth
              variant="filled"
              sx={{
                marginBottom: "1rem",
              }}
            >
              <InputLabel htmlFor="email">Adresa de e-mail</InputLabel>
              <FilledInput
                id="email"
                name="email"
                autoComplete="email"
                disabled
                value={formikUserCredentials.values.email}
                onChange={formikUserCredentials.handleChange}
                onBlur={formikUserCredentials.handleBlur}
                error={
                  formikUserCredentials.touched.email &&
                  Boolean(formikUserCredentials.errors.email)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <CheckCircleIcon
                      width={24}
                      style={{
                        color: "success",
                        marginRight: theme.spacing(-0.5),
                      }}
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="filled"
              sx={{
                marginBottom: "1rem",
              }}
            >
              <InputLabel htmlFor="password">
                {formikUserCredentials.values.password !== ""
                  ? "Parola"
                  : "Introduceți parola"}
              </InputLabel>
              <FilledInput
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="password"
                value={formikUserCredentials.values.password}
                onChange={formikUserCredentials.handleChange}
                onBlur={formikUserCredentials.handleBlur}
                error={
                  formikUserCredentials.touched.password &&
                  Boolean(formikUserCredentials.errors.password)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <EyeSlashIcon width={24} />
                      ) : (
                        <EyeIcon width={24} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormErrorMessage>
                {formikUserCredentials.errors.password}
              </FormErrorMessage>
              <FormErrorMessage>{error}</FormErrorMessage>
            </FormControl>
            {error !== "" && (
              <Stack
                marginTop={3}
                marginBottom={3}
                gap={0.5}
                sx={{
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                  alignItems: {
                    xs: "center",
                    sm: "start",
                  },
                }}
              >
                <Box>
                  <ArrowPathIcon
                    width={18}
                    style={{ verticalAlign: "middle", marginTop: -2 }}
                  />{" "}
                  Ați uitat parola?
                </Box>
                <LinkText
                  component={RouterLink}
                  to={`/recover-password?email=${formikUserCredentials.values.email}`}
                >
                  Click pentru a o recupera.
                </LinkText>
              </Stack>
            )}
            <Box
              marginBottom={3}
              sx={{
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              <FormControlLabel
                disabled={loading}
                control={
                  <Checkbox
                    size="small"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Ține-mă minte"
              />
            </Box>
            <Box
              marginBottom={6}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <Button
                color="primary"
                disabled={loading}
                variant="contained"
                type="submit"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
              >
                Autentificare
              </Button>
            </Box>
            {error === "" && (
              <Stack
                marginTop={3}
                marginBottom={3}
                gap={0.5}
                sx={{
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                  alignItems: {
                    xs: "center",
                    sm: "start",
                  },
                }}
              >
                <Box>
                  <ArrowPathIcon
                    width={18}
                    style={{ verticalAlign: "middle", marginTop: -2 }}
                  />{" "}
                  Ați uitat parola?
                </Box>
                <LinkText
                  component={RouterLink}
                  to={`/recover-password?email=${formikUserCredentials.values.email}`}
                >
                  Click pentru a o recupera.
                </LinkText>
              </Stack>
            )}
          </form>
        </>
      )}
      {currentStep === "no-gdpr" && (
        <Box
        // sx={{ maxWidth: 370 }}
        >
          <form onSubmit={formikUserNoGDPR.handleSubmit}>
            <FormControl
              fullWidth
              variant="filled"
              sx={{
                marginBottom: "1rem",
              }}
            >
              <InputLabel htmlFor="email" style={{ color: "warning" }}>
                Email
              </InputLabel>
              <FilledInput
                id="email"
                name="email"
                autoComplete="email"
                disabled
                value={formikUserNoGDPR.values.email}
                onChange={formikUserNoGDPR.handleChange}
                onBlur={formikUserNoGDPR.handleBlur}
                error={
                  formikUserNoGDPR.touched.email &&
                  Boolean(formikUserNoGDPR.errors.email)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <LockClosedIcon
                      width={20}
                      style={{
                        color: "warning",
                        marginRight: theme.spacing(-0.5),
                      }}
                    />
                  </InputAdornment>
                }
              />
              <FormErrorMessage warning>
                Contul asociat acestui email este temporar restricționat pentru
                că v-ați retras acordul GDPR. Contactați echipa AZ pentru
                detalii.
              </FormErrorMessage>
            </FormControl>
            <Box marginTop={4} marginBottom={6}>
              <Button
                color="primary"
                disabled={loading}
                variant="contained"
                endIcon={<EnvelopeIcon width={20} />}
                type="submit"
              >
                Vreau să fiu contactat
              </Button>
            </Box>
          </form>
        </Box>
      )}
      {currentStep === "no-gdpr-message-sent" && (
        <>
          <Typography>Echipa AZ a fost contactată.</Typography>
          <Box marginTop={8}>
            <Button
              variant="text"
              startIcon={<ArrowLongLeftIcon width={20} />}
              onClick={() => goToStep1(true)}
              sx={{ marginBottom: 2.5 }}
            >
              Înapoi la Autentificare
            </Button>
          </Box>
        </>
      )}
      {currentStep === "account-inactive" && (
        <>
          <Box sx={{ marginBottom: 10 }}>
            <Typography variant="h1" sx={{ marginBottom: 3 }}>
              Verificați e-mailul
            </Typography>
            <Typography>
              Contul asociat acestui email nu este activ. Am trimis linkul de
              activare către adresa {formikCheckEmail.values.email}.
            </Typography>
            <Typography>Vă rugăm să verificați și folder Spam.</Typography>
          </Box>
          <Typography>Nu găsiți e-mailul de activare?</Typography>
          <Button
            color="primary"
            disabled={loading}
            variant="contained"
            endIcon={<ArrowPathIcon width={20} />}
            onClick={resendActivationEmail}
            sx={{ marginBottom: 4 }}
          >
            Retrimite e-mail de activare
          </Button>
          {resendActivationEmailStatus === "ok" && (
            <Typography>
              Linkul de activare a fost retrimis cu succes.
            </Typography>
          )}
          {resendActivationEmailStatus === "error" && (
            <Typography>
              A intervenit o eroare la retrimiterea linkul de activare. Vă rugăm
              contactați echipa AZ.
            </Typography>
          )}

          <Box marginTop={8}>
            <Button
              variant="text"
              startIcon={<ArrowLongLeftIcon width={20} />}
              onClick={() => goToStep1(true)}
              sx={{ marginBottom: 2.5 }}
            >
              Înapoi la Autentificare
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};

export default LoginForm;
