import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Stack } from "@mui/material";
import { GetContentTherapeuticAreas } from "utils/api/getContent";

const Animation = () => {
  const navigate = useNavigate();
  const { data: therapeuticAreasData } = GetContentTherapeuticAreas();
  const therapeuticAreas = therapeuticAreasData
    ? Object.values(therapeuticAreasData)
    : null;

  return (
    therapeuticAreas &&
    therapeuticAreasData && (
      <Box
        sx={{
          position: "relative",
          maxWidth: 840,
          margin: "auto",
          marginTop: {
            xs: 20,
            md: 15,
            lg: 20,
          },
          marginBottom: 3,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            zIndex: 0,
            width: 610,
            height: 400,
            borderRadius: "50%",
            background: "rgba(132, 11, 95, 0.15)",
            filter: "blur(100px)",
            transform: "translate(-50%, -50%)",
          }}
        />
        <Stack direction="row" spacing={3} sx={{ position: "relative" }}>
          {therapeuticAreas.map((therapeuticArea, index) => (
            <Box
              key={`therapeutic-area-${index}`}
              sx={{
                position: "relative",
                cursor: "pointer",
                flex: 1,
                "&:hover .under": {
                  top: "50px",
                },
              }}
              onClick={() => {
                navigate(`/therapeutic-areas/${therapeuticArea.slug}`);
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  position: "relative",
                  zIndex: 1,
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                  justifyContent: "space-between",
                  borderRadius: "20px",
                  padding: 4,
                  background:
                    "linear-gradient(180deg, #830051 0%, #830051 43.9%, #3F0027 100%, #3F0027 100%)",
                }}
              >
                <img
                  src={therapeuticArea.image}
                  height={90}
                  alt={therapeuticArea.title}
                />
                <Typography
                  variant="h3"
                  style={{
                    color: "primary.main",
                    fontWeight: 700,
                    lineHeight: 1.5,
                  }}
                >
                  {therapeuticArea.title}
                </Typography>
              </Box>
              <Box
                className="under"
                sx={{
                  position: "absolute",
                  zIndex: 0,
                  width: "100%",
                  height: "100%",
                  top: "4px",
                  background: "#000",
                  borderRadius: "20px",
                  transition: "all 200ms ease-in-out",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "end",
                  padding: 0.5,
                }}
              >
                <Typography
                  variant="h3"
                  style={{
                    color: "primary.main",
                    fontWeight: 700,
                    lineHeight: 1.5,
                    marginBottom: "0 !important",
                  }}
                >
                  Accesează
                </Typography>
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>
    )
  );
};

export default Animation;
