import React from "react";
import { useParams } from "react-router-dom";
import { GetArchive } from "utils/api/getContent";
import ArchiveSingleEditionSingleEpisode from "components/archive/ArchiveSingleEditionSingleEpisode";
import ArchiveSingleEditionMultipleEpisodes from "components/archive/ArchiveSingleEditionMultipleEpisodes";
import ArchiveMultipleEditionMultipleEpisodes from "components/archive/ArchiveMultipleEditionMultipleEpisodes";

const ArchiveInfo = () => {
  const { archiveSlug, editionSlug = "0", episodeSlug = "0" } = useParams();

  const {
    data: archiveContent,
    // isErrorArchive,
    // isPendingArchive,
  } = GetArchive();

  const archive = archiveContent
    ? archiveContent.find((archiveItem) => archiveItem.slug === archiveSlug)
    : null;

  return (
    archive &&
    archiveContent && (
      <>
        {archive.editions.length === 1 &&
          archive.editions[0].episodes.length === 1 && (
            <ArchiveSingleEditionSingleEpisode
              archive={archive}
              key={`${archiveSlug}-${editionSlug}-${episodeSlug}`}
            />
          )}
        {archive.editions.length === 1 &&
          archive.editions[0].episodes.length > 1 && (
            <ArchiveSingleEditionMultipleEpisodes
              archive={archive}
              key={`${archiveSlug}-${editionSlug}-${episodeSlug}`}
            />
          )}
        {archive.editions.length > 1 && (
          <ArchiveMultipleEditionMultipleEpisodes
            archive={archive}
            key={`${archiveSlug}-${editionSlug}-${episodeSlug}`}
          />
        )}
      </>
    )
  );
};

export default ArchiveInfo;
