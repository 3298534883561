export function getAvailableIndications(
  therapeuticAreasObj,
  therapeuticAreasContentArray
) {
  if (!therapeuticAreasObj) return {};

  if (therapeuticAreasContentArray.length === 0) return therapeuticAreasObj;

  // Merge all items from articles and events
  const articlesAndEvents = therapeuticAreasContentArray
    ? [
        ...therapeuticAreasContentArray.articles,
        ...therapeuticAreasContentArray.events,
      ]
    : [];

  // Reduce the array to the desired object
  const availableTherapeuticAreaIndications = articlesAndEvents.reduce(
    (acc, item) => {
      const area = item.therapeutic_area;
      const indication = item.therapeutic_area_indication;
      if (!acc[area]) {
        acc[area] = [];
      }
      if (!acc[area].includes(indication)) {
        acc[area].push(indication);
      }
      return acc;
    },
    {}
  );

  // console.log(
  //   "availableTherapeuticAreaIndications",
  //   availableTherapeuticAreaIndications
  // );

  // console.log("therapeuticAreasObj", therapeuticAreasObj);

  let therapeuticAreasWithContentTemp = { ...therapeuticAreasObj };

  Object.keys(therapeuticAreasWithContentTemp).forEach((therapeuticArea) => {
    if (!therapeuticAreasWithContentTemp[therapeuticArea].indications) return;

    // therapeuticAreasWithContentTemp[therapeuticArea].indications = [];
    // console.log(
    //   therapeuticAreasObj,
    //   availableTherapeuticAreaIndications[therapeuticArea],
    //   therapeuticAreasWithContentTemp[therapeuticArea].indications,
    //   therapeuticAreasWithContentTemp[therapeuticArea].indications.filter(
    //     (indication) =>
    //       availableTherapeuticAreaIndications[therapeuticArea] !==
    //         undefined &&
    //       availableTherapeuticAreaIndications[therapeuticArea].includes(
    //         indication.slug
    //       )
    //   )
    // );
    therapeuticAreasWithContentTemp[therapeuticArea].indications =
      therapeuticAreasWithContentTemp[therapeuticArea].indications.filter(
        (indication) =>
          availableTherapeuticAreaIndications[therapeuticArea] !== undefined &&
          availableTherapeuticAreaIndications[therapeuticArea].includes(
            indication.slug
          )
      );
  });

  return therapeuticAreasWithContentTemp;
}
