import React, { useState, useContext, useEffect } from "react";
import {
  useNavigate,
  Link as RouterLink,
  useParams,
  Navigate,
} from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  Stack,
  Link,
  Tabs,
  Tab,
} from "@mui/material";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  ArrowLongRightIcon,
  ArrowLongLeftIcon,
} from "@heroicons/react/24/outline";
import theme from "utils/theme";
// import { scrollbarStyles } from "utils/config-design";
import { GetContentTherapeuticAreas, GetArticles } from "utils/api/getContent";
import {
  Icon,
  EventBox,
  ArticleBox,
  VideoBox,
  ButtonExternalLink,
} from "components/common";
import ArticlesSidebar from "components/articles/ArticlesSidebar";
import appContext from "utils/context";
import isFutureDate from "utils/isFutureDate";
// import { articleContentSanitized } from "utils/content";
import useWindowAspectRatio from "utils/useWindowAspectRatio";
import useUserTracking from "utils/useTracking";
import Error404 from "pages/Error404";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`indication-tabpanel-${index}`}
      aria-labelledby={`indication-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingY: 2 }}>{children}</Box>}
    </Box>
  );
};

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };
const a11yProps = (index) => {
  return {
    id: `indication-tab-${index}`,
    "aria-controls": `indication-tabpanel-${index}`,
    sx: {
      padding: theme.spacing(0.5, 1),
      minWidth: "auto",
      minHeight: 38,
      textTransform: "initial",
      color: "secondary.darker",
      fontWeight: 300,
      fontSize: "1rem",
      letterSpacing: "-0.025rem",
      "&.Mui-selected": {
        padding: theme.spacing(0.5, 1),
        color: "secondary.darker",
        fontWeight: 400,
      },
    },
  };
};

const TherapeuticAreaIndicationInfo = () => {
  const { therapeuticAreaSlug, therapeuticAreaIndicationSlug, contentSlug } =
    useParams();

  const { userTrack } = useUserTracking({
    page: therapeuticAreaIndicationSlug,
    therapeutic_area: therapeuticAreaSlug,
    therapeutic_area_indication: therapeuticAreaIndicationSlug,
    content_type: contentSlug || "about",
  });

  const {
    data: therapeuticAreasData,
    // isError,
    // isPending,
  } = GetContentTherapeuticAreas();
  const therapeuticArea = therapeuticAreasData
    ? therapeuticAreasData[therapeuticAreaSlug]
    : null;
  const therapeuticAreaIndication = therapeuticArea?.indications.find(
    (indication) => indication.slug === therapeuticAreaIndicationSlug
  );
  // console.log("therapeuticAreaIndication", therapeuticAreaIndication);
  const indicationCustomTabs =
    therapeuticAreaIndication?.tabs &&
    therapeuticAreaIndication.tabs.find((tab) => tab.id !== null) !== undefined
      ? therapeuticAreaIndication?.tabs.filter((tab) => tab.id !== null)
      : null;
  // console.log("indicationCustomTabs", indicationCustomTabs);
  const navigate = useNavigate();

  const [contentSlugs, setContentSlugs] = useState(null);
  useEffect(() => {
    let tabs;
    if (indicationCustomTabs) {
      const indicationCustomTabsSlugs = indicationCustomTabs.map(
        (tab) => tab.slug
      );
      if (therapeuticAreaIndication.custom_tabs === 1) {
        tabs = [
          undefined,
          "articles",
          "webinars",
          "repository",
          ...indicationCustomTabsSlugs,
        ];
      } else {
        tabs = indicationCustomTabsSlugs;
      }
    } else {
      tabs = [undefined, "articles", "webinars", "repository"];
    }
    // console.log("tabs: ", tabs);
    setContentSlugs(tabs);
    if (
      contentSlug !== "" &&
      tabs.findIndex((item) => item === contentSlug) === -1
    ) {
      navigate(
        `/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}`
      );
    }
    setIndicationTab(
      tabs.findIndex((item) => item === contentSlug) !== -1
        ? tabs.findIndex((item) => item === contentSlug)
        : 0
    );
  }, [contentSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  // TABS
  const [indicationTab, setIndicationTab] = useState(null);
  const changeIndicationTab = (event, newTab) => {
    navigate(
      `/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${
        newTab !== 0 ? contentSlugs[newTab] : ""
      }`
    );
    userTrack({
      page: therapeuticAreaIndicationSlug,
      therapeutic_area: therapeuticAreaSlug,
      therapeutic_area_indication: therapeuticAreaIndicationSlug,
      content_type: newTab !== 0 ? contentSlugs[newTab] : "about",
    });
    // setIndicationTab(newTab);
  };
  // useEffect(() => {
  //   setIndicationTab(contentSlugs.findIndex((item) => item === contentSlug) || 0);
  // }, [contentSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  // CONTENT
  const {
    data: therapeuticAreasContent,
    // isErrorArticles,
    // isPendingArticles,
  } = GetArticles();

  const { serverTime, setIsWhiteContentPage } = useContext(appContext);
  useEffect(() => {
    setIsWhiteContentPage(true);

    return () => {
      setIsWhiteContentPage(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const indicationEventsFuture = therapeuticAreasContent?.events?.filter(
    (event) =>
      isFutureDate(event.date, serverTime) &&
      event.therapeutic_area_indication === therapeuticAreaIndicationSlug
  );

  const indicationEvents = therapeuticAreasContent?.events?.filter(
    (event) =>
      event.therapeutic_area_indication === therapeuticAreaIndicationSlug
  );

  const indicationArticles = therapeuticAreasContent?.articles.filter(
    (article) =>
      article.therapeutic_area_indication === therapeuticAreaIndicationSlug &&
      (article.type === "text" || article.type === "material")
  );

  // console.log(therapeuticAreasContent?.articles.filter((article) => article.type === "material"));

  const indicationVideos = therapeuticAreasContent?.articles.filter(
    (article) =>
      article.therapeutic_area_indication === therapeuticAreaIndicationSlug &&
      article.type === "video"
  );

  const indicationArticlesAndVideos = therapeuticAreasContent?.articles.filter(
    (article) =>
      article.therapeutic_area_indication === therapeuticAreaIndicationSlug &&
      (article.type === "text" ||
        article.type === "material" ||
        article.type === "video") &&
      (article.tab_id !== null || article.tab_id !== undefined)
  );

  const indicationVideosDisplayed =
    !indicationEventsFuture || indicationEventsFuture?.length === 0 ? 4 : 2;

  const indicationArticlesDisplayed =
    !indicationEventsFuture || indicationEventsFuture?.length === 0 ? 4 : 2;

  const TabPanelAbout = () => {
    return (
      <TabPanel value={indicationTab} index={0}>
        <Grid container columnSpacing={8} rowSpacing={2}>
          <Grid item xs={12} lg={8}>
            {indicationEventsFuture?.length > 0 && (
              <Box paddingTop={4.25} marginBottom={4}>
                <Box>
                  <EventBox event={indicationEventsFuture[0]} isPromo />
                </Box>
                <Box>
                  <Button
                    variant="text"
                    endIcon={<ArrowLongRightIcon width={20} />}
                    component={RouterLink}
                    sx={{ marginTop: 1, color: "secondary.darker" }}
                    to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/webinars`}
                  >
                    Vezi toate
                  </Button>
                </Box>
              </Box>
            )}
            {indicationArticles?.length > 0 && (
              <Box>
                <Typography
                  color="primary.black"
                  component="p"
                  sx={{
                    fontWeight: 400,
                    marginBottom: "0.5rem !important",
                    fontSize: { xs: "1rem" },
                  }}
                >
                  Articole
                </Typography>
                <Grid container spacing={2} alignItems="stretch">
                  {indicationArticles.map(
                    (article, index) =>
                      index < indicationArticlesDisplayed && (
                        <Grid item xs={12} sm={6} key={`article-${index}`}>
                          <ArticleBox article={article} isWhite />
                        </Grid>
                      )
                  )}
                </Grid>
                {indicationArticles?.length >= indicationArticlesDisplayed && (
                  <Button
                    variant="text"
                    endIcon={<ArrowLongRightIcon width={20} />}
                    component={RouterLink}
                    sx={{ marginTop: 1, color: "secondary.darker" }}
                    to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/articles`}
                  >
                    Vezi toate
                  </Button>
                )}
              </Box>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            {indicationVideos?.length > 0 && (
              <>
                <Typography
                  color="primary.black"
                  component="p"
                  sx={{
                    fontWeight: 400,
                    marginBottom: "0.5rem !important",
                    fontSize: { xs: "1rem" },
                  }}
                >
                  Video
                </Typography>
                <Grid container spacing={2}>
                  {indicationVideos.map(
                    (video, index) =>
                      index < indicationVideosDisplayed && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={12}
                          key={`video-${index}`}
                        >
                          <VideoBox video={video} isWhite />
                        </Grid>
                      )
                  )}
                </Grid>
                {indicationVideos?.length >= indicationVideosDisplayed && (
                  <Button
                    variant="text"
                    endIcon={<ArrowLongRightIcon width={20} />}
                    component={RouterLink}
                    sx={{ marginTop: 1, color: "secondary.darker" }}
                    to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/webinars`}
                  >
                    Vezi toate
                  </Button>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  const TabPanelArticles = ({
    tabID = null,
    excludeCustomTabsContent = false,
  }) => {
    return tabID === null ? (
      indicationArticles.find(
        (article) => article.tab_id === null || article.tab_id === undefined
      ) && (
        <TabPanel value={indicationTab} index={1}>
          <Grid container spacing={4}>
            {indicationArticles
              .filter((article) =>
                excludeCustomTabsContent !== null
                  ? article.tab_id === null || article.tab_id === undefined
                  : true
              )
              .map((article, index) => (
                <Grid item xs={12} sm={6} lg={4} key={`article-${index}`}>
                  <ArticleBox article={article} isWhite isDetailed />
                </Grid>
              ))}
          </Grid>
        </TabPanel>
      )
    ) : (
      <TabPanel
        value={indicationTab}
        index={
          therapeuticAreaIndication.custom_tabs === 1 ? tabID + 3 : tabID - 1
        }
      >
        <Grid container spacing={4}>
          {indicationArticlesAndVideos.filter(
            (article) => article.tab_id === tabID
          ).length ? (
            indicationArticlesAndVideos
              .filter((article) => article.tab_id === tabID)
              .map((article, index) => (
                <Grid item xs={12} sm={6} lg={4} key={`article-${index}`}>
                  {article.type === "video" ? (
                    <VideoBox video={article} isWhite />
                  ) : (
                    <ArticleBox article={article} isWhite isDetailed />
                  )}
                </Grid>
              ))
          ) : (
            <Grid item xs={12}>
              <Typography>Pagină în construcție.</Typography>
            </Grid>
          )}
        </Grid>
      </TabPanel>
    );
  };

  // const TabPanelWebinars = () => {
  //   return (
  //     (indicationVideos?.length > 0 || indicationEvents?.length > 0) && (
  //       <TabPanel value={indicationTab} index={2}>
  //         <Grid container spacing={4} alignItems="stretch">
  //           {indicationEvents?.length > 0 &&
  //             indicationEvents.map((event, index) => (
  //               <Grid item xs={12} sm={6} lg={4} key={`event-${index}`}>
  //                 <EventBox event={event} isWhite />
  //               </Grid>
  //             ))}
  //           {indicationVideos?.length > 0 &&
  //             indicationVideos.map((video, index) => (
  //               <Grid item xs={12} sm={6} lg={4} key={`video-${index}`}>
  //                 <VideoBox video={video} isWhite />
  //               </Grid>
  //             ))}
  //         </Grid>
  //       </TabPanel>
  //     )
  //   );
  // };

  const TabPanelWebinars = ({ excludeCustomTabsContent = false }) => {
    // console.log("indicationTab", indicationTab);
    // console.log("indicationVideos", indicationVideos);
    return (
      indicationVideos.find(
        (video) => video.tab_id === null || video.tab_id === undefined
      ) && (
        <TabPanel value={indicationTab} index={2}>
          <Grid container spacing={4} alignItems="stretch">
            {indicationEvents?.length > 0 &&
              indicationEvents.map((event, index) => (
                <Grid item xs={12} sm={6} lg={4} key={`event-${index}`}>
                  <EventBox event={event} isWhite />
                </Grid>
              ))}
            {indicationVideos
              .filter((video) =>
                excludeCustomTabsContent !== null
                  ? video.tab_id === null || video.tab_id === undefined
                  : true
              )
              .map((video, index) => (
                <Grid item xs={12} sm={6} key={`video-${index}`}>
                  <VideoBox video={video} isWhite />
                </Grid>
              ))}
          </Grid>
        </TabPanel>
      )
    );
  };

  const TabPanelRepository = () => {
    return (
      <TabPanel value={indicationTab} index={3}>
        <Box paddingY={3}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={7} lg={8}>
              <Stack gap={3}>
                {therapeuticAreaIndication.repository &&
                  therapeuticAreaIndication.repository.map(
                    (repository, index) => (
                      <Box key={`repository-${index}`}>
                        {repository.description && (
                          <Box marginBottom={4}>
                            <Typography sx={{ fontSize: "1.25rem" }}>
                              {repository.description}
                            </Typography>
                          </Box>
                        )}
                        {Object.keys(repository.files).length && (
                          <Grid
                            container
                            spacing={{ xs: 4, lg: 6 }}
                            alignItems="stretch"
                          >
                            {Object.values(repository.files).map(
                              (file, indexFile) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  key={`repository-${index}-${indexFile}`}
                                >
                                  <ButtonExternalLink
                                    fullWidth
                                    component={Link}
                                    href={file.file_url}
                                    target="_blank"
                                    rel="noreferrer"
                                    variant="text"
                                    sx={{
                                      minHeight: 190,
                                      height: "100%",
                                      padding: theme.spacing(3, 3, 5, 3),
                                      display: "flex",
                                      flexDirection: "column",
                                      borderRadius: "1.875rem",
                                      background:
                                        "linear-gradient(268deg, #670040 -0.83%, #830051 21.85%, #250017 71.86%, #690041 97.37%)",
                                      "&:hover": {
                                        borderColor: "secondary.darker",
                                        "& .MuiButton-endIcon": {
                                          width: 28,
                                        },
                                      },
                                    }}
                                  >
                                    <Stack sx={{ flexGrow: 1 }}>
                                      <Box>
                                        <Typography
                                          sx={{
                                            fontSize: "1.125rem",
                                            textAlign: "center",
                                          }}
                                        >
                                          {file.type}
                                        </Typography>
                                      </Box>
                                      <Stack
                                        sx={{
                                          flexGrow: 1,
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "1.5rem",
                                            fontWeight: 700,
                                            textAlign: "center",
                                          }}
                                        >
                                          {file.title}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </ButtonExternalLink>
                                </Grid>
                              )
                            )}
                          </Grid>
                        )}
                      </Box>
                    )
                  )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
              <ArticlesSidebar isWhite />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    );
  };

  const { windowWidth } = useWindowAspectRatio();
  const theme = useTheme();
  // const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return therapeuticAreasContent &&
    contentSlugs &&
    therapeuticAreaIndication !== null ? (
    therapeuticAreaIndication ? (
      indicationArticlesAndVideos.length > 0 || indicationEvents.length > 0 ? (
        <Box
          sx={{
            background:
              "linear-gradient(180deg, transparent 379px, rgba(255,255,255,1) 379px)",
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "start",
          }}
        >
          <Box
            sx={{
              height: { xs: 160, sm: 180 },
              position: "relative",
              // background: `url(/assets/images/products/banners/${therapeuticAreaSlug}.png) transparent no-repeat center`,
              // backgroundSize: "cover",
            }}
          >
            <video
              muted
              loop
              autoPlay
              playsInline
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center right",
              }}
            >
              <source
                src={`/assets/images/products/banners/${therapeuticAreaSlug}.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Box>
          <Container maxWidth="xl">
            <Box paddingY={3}>
              <Box>
                <Button
                  component={RouterLink}
                  variant="text"
                  startIcon={<ArrowLongLeftIcon width={20} />}
                  to={`/therapeutic-areas/${therapeuticAreaSlug}`}
                  sx={{ fontSize: "0.875rem", color: "primary.main" }}
                >
                  Înapoi la {therapeuticArea.title}
                </Button>
              </Box>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="h1" sx={{ fontWeight: 700 }}>
                  {therapeuticAreaIndication.title}
                </Typography>
                <Icon
                  name={`indicatii/white/${therapeuticAreaIndication.slug}`}
                  width={24}
                />
              </Stack>
            </Box>
          </Container>
          <Box
            sx={{
              background: "rgba(255,255,255,1)",
              paddingTop: 2,
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                position: "relative",
                paddingBottom: {
                  xs: "20px",
                  sm: "140px",
                  md: "120px",
                  lg: "100px",
                },
                height: windowWidth >= 1280 ? "100%" : "auto",
                minHeight: windowWidth >= 1280 ? "100%" : "auto",
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "center",
              }}
            >
              <Box paddingTop={2} sx={{ color: "primary.black" }}>
                {indicationCustomTabs ? (
                  therapeuticAreaIndication.custom_tabs !== 1 ? (
                    <>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "secondary.lighter",
                        }}
                      >
                        <Tabs
                          value={indicationTab}
                          onChange={changeIndicationTab}
                          aria-label="Tab-uri produs"
                          sx={{
                            minHeight: 38,
                            "& .MuiTabs-indicator": {
                              background: theme.palette.secondary.darker,
                            },
                          }}
                          variant="scrollable"
                          scrollButtons="auto"
                        >
                          {indicationCustomTabs.map((tab, index) => (
                            <Tab
                              key={`tab-${index}`}
                              label={tab.title}
                              value={index}
                              {...a11yProps(index)}
                            />
                          ))}
                        </Tabs>
                      </Box>
                      <TabPanelArticles
                        tabID={parseInt(indicationCustomTabs[indicationTab].id)}
                      />
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "secondary.lighter",
                        }}
                      >
                        <Tabs
                          value={indicationTab}
                          onChange={changeIndicationTab}
                          aria-label="Tab-uri produs"
                          sx={{
                            minHeight: 38,
                            "& .MuiTabs-indicator": {
                              background: theme.palette.secondary.darker,
                            },
                          }}
                          variant="scrollable"
                          scrollButtons="auto"
                        >
                          <Tab label="Despre" value={0} {...a11yProps(0)} />
                          {indicationArticles.find(
                            (article) =>
                              article.tab_id === null ||
                              article.tab_id === undefined
                          ) && (
                            <Tab label="Articole" value={1} {...a11yProps(1)} />
                          )}
                          {(indicationVideos?.filter(
                            (video) =>
                              video.tab_id === null ||
                              video.tab_id === undefined
                          ).length > 0 ||
                            indicationEvents?.length > 0) && (
                            <Tab
                              label="Webinarii"
                              value={2}
                              {...a11yProps(3)}
                            />
                          )}
                          {therapeuticAreaIndication?.repository && (
                            <Tab
                              label="Repository"
                              value={3}
                              {...a11yProps(4)}
                            />
                          )}
                          {indicationCustomTabs.map((tab, index) => (
                            <Tab
                              key={`tab-${index + 4}`}
                              label={tab.title}
                              value={index + 4}
                              {...a11yProps(index + 4)}
                            />
                          ))}
                        </Tabs>
                      </Box>
                      {indicationArticles?.length === 0 &&
                        indicationVideos?.length === 0 &&
                        indicationEvents?.length === 0 && (
                          <Box mt={3}>Pagină în construcție.</Box>
                        )}
                      <TabPanelAbout />
                      <TabPanelArticles excludeCustomTabsContent />
                      <TabPanelWebinars excludeCustomTabsContent />
                      <TabPanelRepository />
                      {indicationCustomTabs[indicationTab - 4]?.id && (
                        <TabPanelArticles
                          tabID={parseInt(
                            indicationCustomTabs[indicationTab - 4].id
                          )}
                        />
                      )}
                    </>
                  )
                ) : (
                  <>
                    <Box
                      sx={{ borderBottom: 1, borderColor: "secondary.lighter" }}
                    >
                      <Tabs
                        value={indicationTab}
                        onChange={changeIndicationTab}
                        aria-label="Tab-uri produs"
                        sx={{
                          minHeight: 38,
                          "& .MuiTabs-indicator": {
                            background: theme.palette.secondary.darker,
                          },
                        }}
                      >
                        <Tab label="Despre" value={0} {...a11yProps(0)} />
                        {indicationArticles?.length > 0 && (
                          <Tab label="Articole" value={1} {...a11yProps(1)} />
                        )}
                        {(indicationVideos?.length > 0 ||
                          indicationEvents?.length > 0) && (
                          <Tab label="Webinarii" value={2} {...a11yProps(3)} />
                        )}
                        {therapeuticAreaIndication?.repository && (
                          <Tab label="Repository" value={3} {...a11yProps(4)} />
                        )}
                      </Tabs>
                    </Box>
                    {indicationArticles?.length === 0 &&
                      indicationVideos?.length === 0 &&
                      indicationEvents?.length === 0 && (
                        <Box mt={3}>Pagină în construcție.</Box>
                      )}

                    <TabPanelAbout />
                    <TabPanelArticles />
                    <TabPanelWebinars />
                    <TabPanelRepository />
                  </>
                )}
              </Box>
            </Container>
          </Box>
        </Box>
      ) : (
        <Navigate to={`/therapeutic-areas/${therapeuticAreaSlug}`} replace />
      )
    ) : (
      <Error404 />
    )
  ) : null;
};

export default TherapeuticAreaIndicationInfo;
