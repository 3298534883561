import React, { useContext, useEffect } from "react";
import {
  useParams,
  // useNavigate,
  // Link as RouterLink,
} from "react-router-dom";
import { Typography, Box, Stack, Grid } from "@mui/material";
import theme from "utils/theme";
import { GetContentTherapeuticAreas, GetArticles } from "utils/api/getContent";
import { EventBox, ArticleBox, VideoBox } from "components/common";
import appContext from "utils/context";
import isFutureDate from "utils/isFutureDate";

const ArticlesSidebar = () => {
  const {
    data: therapeuticAreasData,
    // isError,
    // isPending,
  } = GetContentTherapeuticAreas();

  const {
    data: therapeuticAreasContent,
    // isErrorArticles,
    // isPendingArticles,
  } = GetArticles();

  const { serverTime } = useContext(appContext);
  const {
    // therapeuticAreaSlug,
    therapeuticAreaIndicationSlug,
    articleSlug,
  } = useParams();

  useEffect(() => {
    const storedArticlesViewed = localStorage.getItem("az-articles");
    // console.log("articleSidebar > storedArticlesViewed", storedArticlesViewed);
    if (storedArticlesViewed) {
      const storedArticlesViewedArray = JSON.parse(storedArticlesViewed);
      // console.log(
      //   "articleSidebar > storedArticlesViewedArray",
      //   storedArticlesViewedArray
      // );

      // check for articles
      const articlesList = getContentList(
        therapeuticAreasContent?.articles,
        "article",
        false
      );
      if (
        articlesList.length &&
        therapeuticAreaIndicationArticles.length === 0
      ) {
        // console.log("reset viewed local storage");
        articlesList.forEach((article) => {
          const indexArticle = storedArticlesViewedArray.indexOf(
            `/therapeutic-areas/${article.therapeutic_area}/${article.therapeutic_area_indication}/articles/${article.slug}`
          );
          if (indexArticle !== -1) {
            storedArticlesViewedArray.splice(indexArticle, 1);
          }
        });
        try {
          localStorage.setItem(
            "az-articles",
            JSON.stringify(storedArticlesViewedArray)
          );
        } catch (error) {}
      }

      // check for videos
      const videosList = getContentList(
        therapeuticAreasContent?.articles,
        "video",
        false
      );
      if (videosList.length && therapeuticAreaIndicationVideos.length === 0) {
        // console.log("reset viewed local storage");
        videosList.forEach((article) => {
          const indexVideo = storedArticlesViewedArray.indexOf(
            `/therapeutic-areas/${article.therapeutic_area}/${article.therapeutic_area_indication}/videos/${article.slug}`
          );
          if (indexVideo !== -1) {
            storedArticlesViewedArray.splice(indexVideo, 1);
          }
        });
        try {
          localStorage.setItem(
            "az-articles",
            JSON.stringify(storedArticlesViewedArray)
          );
        } catch (error) {}
      }
    }
  }, [therapeuticAreasContent]); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log("articleSidebar > articleSlug", articleSlug);

  // const therapeuticArea = therapeuticAreasData
  //   ? therapeuticAreasData[therapeuticAreaSlug]
  //   : null;
  // const therapeuticAreaIndication = therapeuticArea?.indications.find(
  //   (indication) => indication.slug === therapeuticAreaIndicationSlug
  // );
  // const navigate = useNavigate();

  const therapeuticAreaIndicationEvents =
    therapeuticAreasContent?.events
      ?.filter(
        (event) =>
          isFutureDate(event.date, serverTime) &&
          event.therapeutic_area_indication === therapeuticAreaIndicationSlug
      )
      .filter((_, index) => index < 7) || [];

  function getContentList(articles, type = "article", checkViewed = true) {
    if (!articles) return [];

    let articlesFiltered = articles.filter((article) => {
      const check1 =
        article.therapeutic_area_indication === therapeuticAreaIndicationSlug &&
        article.slug !== articleSlug &&
        (type === "article"
          ? article.type === "text" || article.type === "material"
          : article.type === "video");

      let check2 = true;
      if (checkViewed && localStorage.getItem("az-articles")) {
        check2 = !JSON.parse(localStorage.getItem("az-articles")).includes(
          `/therapeutic-areas/${article.therapeutic_area}/${
            article.therapeutic_area_indication
          }/${type === "article" ? "articles" : "videos"}/${article.slug}`
        );
      }

      return check1 && check2;
    });

    return articlesFiltered;
  }

  const therapeuticAreaIndicationArticlesAll =
    therapeuticAreasContent?.articles.filter(
      (article) =>
        article.therapeutic_area_indication === therapeuticAreaIndicationSlug &&
        article.slug !== articleSlug &&
        (article.type === "text" || article.type === "material")
    ) || [];

  const therapeuticAreaIndicationArticles =
    getContentList(therapeuticAreasContent?.articles).filter(
      (_, index) => index < 7
    ) || [];

  const therapeuticAreaIndicationArticlesAllDisplayed =
    therapeuticAreaIndicationArticles.concat(
      therapeuticAreaIndicationArticlesAll
    );

  const therapeuticAreaIndicationArticlesDisplayed = Array.from(
    new Set(
      therapeuticAreaIndicationArticlesAllDisplayed.map((obj) => obj.slug)
    )
  )
    .map((slug) =>
      therapeuticAreaIndicationArticlesAllDisplayed.find(
        (obj) => obj.slug === slug
      )
    )
    .filter((_, index) => index < 7);

  const therapeuticAreaIndicationVideosAll =
    therapeuticAreasContent?.articles.filter(
      (article) =>
        article.therapeutic_area_indication === therapeuticAreaIndicationSlug &&
        article.slug !== articleSlug &&
        article.type === "video"
    ) || [];

  const therapeuticAreaIndicationVideos =
    getContentList(therapeuticAreasContent?.articles, "video").filter(
      (_, index) => index < 7
    ) || [];

  const therapeuticAreaIndicationVideosAllDisplayed =
    therapeuticAreaIndicationVideos.concat(therapeuticAreaIndicationVideosAll);

  const therapeuticAreaIndicationVideosDisplayed = Array.from(
    new Set(therapeuticAreaIndicationVideosAllDisplayed.map((obj) => obj.slug))
  )
    .map((slug) =>
      therapeuticAreaIndicationVideosAllDisplayed.find(
        (obj) => obj.slug === slug
      )
    )
    .filter((_, index) => index < 7);

  return (
    therapeuticAreasData &&
    therapeuticAreasContent &&
    therapeuticAreaIndicationEvents.length +
      therapeuticAreaIndicationArticlesDisplayed.length +
      therapeuticAreaIndicationVideosDisplayed.length >
      0 && (
      <Stack spacing={3}>
        {therapeuticAreaIndicationEvents.length > 0 && (
          <Box>
            <Typography sx={{ marginBottom: `${theme.spacing(1)} !important` }}>
              Evenimente viitoare
            </Typography>
            <Grid container spacing={2}>
              {therapeuticAreaIndicationEvents.map((event, index) => (
                <Grid item xs={12} sm={6} md={12} key={`event-${index}`}>
                  <EventBox isHorizontal isSmall event={event} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {therapeuticAreaIndicationArticlesDisplayed.length > 0 &&
          therapeuticAreaIndicationEvents.length < 7 && (
            <Box>
              <Typography
                sx={{ marginBottom: `${theme.spacing(1)} !important` }}
              >
                Alte articole
              </Typography>
              <Grid container spacing={2}>
                {therapeuticAreaIndicationArticlesDisplayed
                  .filter(
                    (_, index) =>
                      index <
                      Math.max(
                        Math.ceil(
                          (7 - therapeuticAreaIndicationEvents.length) / 2
                        ),
                        7 -
                          therapeuticAreaIndicationEvents.length -
                          therapeuticAreaIndicationVideosDisplayed.length
                      )
                  )
                  .map((article, index) => (
                    <Grid item xs={12} sm={6} md={12} key={`article-${index}`}>
                      <ArticleBox
                        isHorizontal
                        isSmall
                        isSquareImage
                        article={article}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          )}
        {therapeuticAreaIndicationVideosDisplayed.length > 0 &&
          therapeuticAreaIndicationEvents.length +
            Math.min(
              therapeuticAreaIndicationArticlesDisplayed.length,
              Math.ceil((7 - therapeuticAreaIndicationEvents.length) / 2)
            ) <
            7 && (
            <Box>
              <Typography
                sx={{ marginBottom: `${theme.spacing(1)} !important` }}
              >
                Video
              </Typography>
              <Grid container spacing={2}>
                {therapeuticAreaIndicationVideosDisplayed
                  .filter(
                    (_, index) =>
                      index <
                      Math.floor(
                        7 -
                          therapeuticAreaIndicationEvents.length -
                          Math.min(
                            therapeuticAreaIndicationArticlesDisplayed.length,
                            Math.ceil(
                              (7 - therapeuticAreaIndicationEvents.length) / 2
                            )
                          )
                      )
                  )
                  .map((video, index) => (
                    <Grid item xs={12} sm={6} md={12} key={`video-${index}`}>
                      <VideoBox
                        isHorizontal
                        isSmall
                        isSquareImage
                        video={video}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          )}
      </Stack>
    )
  );
};

export default ArticlesSidebar;
