import React, { useState } from "react";
import {
  useNavigate,
  // Link as RouterLink,
  useParams,
} from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  TextField,
  Autocomplete,
  Paper,
  // backdropClasses,
} from "@mui/material";
import { GetArticles } from "utils/api/getContent";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
// import theme from "utils/theme";

const AutocompleteSearch = styled(Autocomplete)(({ theme }) => ({}));

const PaperSearch = styled(Paper)(({ theme }) => ({
  background: "#6F0145",
  borderRadius: "0.625rem",
  "& .MuiAutocomplete-option": {
    lineHeight: 1.3,
  },
}));

const TextFieldSearch = styled(TextField)(({ theme }) => ({
  "& .MuiFilledInput-input": {
    padding: "0.25rem 2rem 0.25rem 1rem !important",
    height: "2.5rem",
    boxShadow: "none",
  },
  "& .MuiFilledInput-root": {
    height: "2.5rem",
    border: "none",
    boxShadow: "none",
    "&:after": {
      background: "rgba(236,237,238,0.2)",
      // background: "none",
      mixBlendMode: "normal",
      boxShadow: "none",
    },
    "& input": {
      backdropFilter: "none",
    },
    "&.Mui-focused": {
      boxShadow: "none",
    },
    "&.Mui-focused input": {
      backdropFilter: "none",
    },
  },
}));

function extractUniqueTags(content) {
  if (!content) return [];
  const uniqueTags = new Set();

  // Iterate over each object in the content
  for (const object of Object.values(content)) {
    // Iterate over each article or event in the object
    for (const item of object) {
      // Add each tag to the set, ensuring uniqueness
      if (item.tags && item.tags.length) {
        item.tags.forEach((tag) => {
          uniqueTags.add(tag);
        });
      }
    }
  }

  // Convert the set back to an array if needed
  const uniqueTagsArray = Array.from(uniqueTags);
  // console.log("articlesTags", uniqueTagsArray);
  return uniqueTagsArray;
}

const SearchBox = ({ maxWidth = 160 }) => {
  const { tag } = useParams();
  const cleanedTag = tag
    ? decodeURI(tag).replace(/[^a-zA-Z0-9\s\u0080-\uFFFF-]/g, "")
    : undefined;

  // CONTENT
  const {
    data: allArticles,
    // isErrorArticles,
    // isPendingArticles,
  } = GetArticles();

  const navigate = useNavigate();

  const articlesTags = extractUniqueTags(allArticles);

  const [inputValue, setInputValue] = useState(cleanedTag || "");
  const [filteredValues, setFilteredValues] = useState([]);

  // useEffect(() => {
  //   console.log("checking");
  //   if (inputValue.length >= 2) {
  //     const filtered = articlesTags.filter((value) =>
  //       value.toLowerCase().includes(inputValue.toLowerCase())
  //     );
  //     setFilteredValues(filtered);
  //   } else {
  //     setFilteredValues([]);
  //   }
  // }, [inputValue, articlesTags]);

  const handleInputChange = (event, newInputValue) => {
    const regex = /^[a-zA-Z0-9\s\u0080-\uFFFF-]*$/;
    if (regex.test(newInputValue)) {
      setInputValue(newInputValue);

      if (newInputValue.length >= 2) {
        const filtered = articlesTags.filter((value) =>
          value.toLowerCase().includes(newInputValue.toLowerCase())
        );
        setFilteredValues(filtered);
      } else {
        setFilteredValues([]);
      }
    }
  };

  const handleChange = (event, value) => {
    navigate("/search/" + value);
  };

  return (
    <Box>
      <AutocompleteSearch
        options={filteredValues}
        getOptionLabel={(option) => option}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        freeSolo
        forcePopupIcon={false}
        noOptionsText="Niciun rezultat"
        PaperComponent={PaperSearch}
        renderInput={(params) => (
          <TextFieldSearch
            {...params}
            hiddenLabel
            placeholder="Căutare"
            variant="filled"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Box
                  sx={{
                    position: "absolute",
                    top: 7,
                    right: "0.5rem",
                    opacity: 0.5,
                  }}
                >
                  <MagnifyingGlassIcon width={16} />
                </Box>
              ),
            }}
            sx={{ width: maxWidth }}
          />
        )}
      />
    </Box>
  );
};

export default SearchBox;
