import React, { useEffect } from "react";
import {
  useNavigate,
  // Link as RouterLink,
  useParams,
} from "react-router-dom";
import { Typography, Box, Stack, Grid } from "@mui/material";
import { PlayIcon } from "@heroicons/react/24/solid";
import ArchiveContainer from "components/archive/ArchiveContainer";
import ArchiveVideoPlayer from "components/archive/ArchiveVideoPlayer";
import { ContentBoxHover } from "components/common";
import useUserTracking from "utils/useTracking";

const ArchiveSingleEditionMultipleEpisodes = ({ archive }) => {
  // console.log("ArchiveSingleEditionMultipleEpisodes");
  const { archiveSlug, editionSlug, episodeSlug } = useParams();

  const { userTrack } = useUserTracking({ stop: true });

  const edition =
    archive.editions.find((edition) => edition.slug === editionSlug) ?? null;
  const episode =
    edition?.episodes.find((episode) => episode.slug === episodeSlug) ?? null;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(
      `/archive/${archiveSlug}/${editionSlug || archive.editions[0].slug}/${
        episodeSlug ||
        archive.editions.find((edition) => edition.slug === editionSlug)
          ?.episodes[0].slug ||
        archive.editions[0].episodes[0].slug
      }`
    );
    if (editionSlug && episodeSlug) {
      userTrack({
        page: archiveSlug,
        therapeutic_area_indication: editionSlug || archive.editions[0].slug,
        content_type:
          episodeSlug ||
          archive.editions.find((edition) => edition.slug === editionSlug)
            ?.episodes[0].slug ||
          archive.editions[0].episodes[0].slug,
      });
    }
  }, [editionSlug, episodeSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    edition &&
    episode && (
      <ArchiveContainer archive={archive}>
        {edition.title && (
          <Box marginBottom={3} marginTop={-3}>
            <Typography variant="h2" component="span" sx={{ fontWeight: 500 }}>
              {edition.title}
              {edition.description ? ":" : ""}
            </Typography>
            {edition.description && (
              <Typography
                variant="h2"
                component="span"
                sx={{ paddingLeft: 1.5 }}
              >
                {edition.description}
              </Typography>
            )}
          </Box>
        )}
        <Grid container spacing={2} alignItems="start">
          <Grid item xs={12} md={8}>
            <Box marginBottom={2}>
              <ArchiveVideoPlayer fileURL={episode.video_url} />
            </Box>
            <Box paddingX={2} paddingY={3}>
              <Stack gap={1}>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{ fontWeight: 500, margin: "0 !important" }}
                  >
                    {episode.title}
                    {episode.duration ? " - " + episode.duration : ""}
                  </Typography>
                </Box>
                <Box marginBottom={2}>
                  {episode.video_text ? (
                    <>
                      <Typography
                        variant="h2"
                        sx={{ fontWeight: 500, margin: "0 !important" }}
                      >
                        {episode.video_text}
                      </Typography>
                      <Typography
                        variant="h3"
                        sx={{ fontWeight: 300, margin: "0 !important" }}
                      >
                        {episode.subtitle}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="h2"
                      sx={{ fontWeight: 500, margin: "0 !important" }}
                    >
                      {episode.subtitle}
                    </Typography>
                  )}
                </Box>
                <Grid container spacing={4} alignItems="start">
                  {episode.guest && (
                    <Grid item xs={12} md={episode.moderator ? 8 : 12}>
                      <Box>
                        <Typography>Invitatul emisiunii:</Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontWeight: 500 }}>
                          {episode.guest.name}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ lineHeight: 1.3 }}>
                          {episode.guest.position}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontStyle: "italic" }}>
                          {episode.guest.country}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {episode.moderator && (
                    <Grid item xs={12} md={episode.guest ? 4 : 12}>
                      <Box>
                        <Typography>
                          Moderator
                          {episode.moderator.company
                            ? ", " + episode.moderator.company
                            : ""}
                          :
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontWeight: 500 }}>
                          {episode.moderator.name}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
                {episode.approval_code && (
                  <Box marginTop={3}>
                    <Typography variant="body2">
                      {episode.approval_code}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack direction="column" gap={0}>
              {edition.episodes
                .filter((episode) => episode.slug !== episodeSlug)
                .map((episode, index) => (
                  <ContentBoxHover
                    key={`episode-${index}`}
                    onClick={() =>
                      navigate(
                        `/archive/${archiveSlug}/${editionSlug}/${episode.slug}`
                      )
                    }
                  >
                    <Box
                      padding={2}
                      sx={{
                        "&:hover .play-icon": {
                          opacity: 1,
                        },
                      }}
                    >
                      <Grid container spacing={2} alignItems="start">
                        <Grid item xs={5}>
                          <Box position="relative">
                            <img
                              src={episode.image_url}
                              alt={episode.title}
                              style={{ width: "100%" }}
                            />
                            <Box
                              className="play-icon"
                              sx={{
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                zIndex: 1,
                                transform: "translate(-50%, -50%)",
                                opacity: 0.5,
                                transition: "all 200ms ease-in-out",
                              }}
                            >
                              <PlayIcon width={48} />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={7}>
                          <Box>
                            <Box>
                              <Typography>
                                {episode.title}
                                {episode.duration
                                  ? " - " + episode.duration
                                  : ""}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant="h3" sx={{ lineHeight: 1.3 }}>
                                {episode.subtitle}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </ContentBoxHover>
                ))}
            </Stack>
          </Grid>
        </Grid>
      </ArchiveContainer>
    )
  );
};

export default ArchiveSingleEditionMultipleEpisodes;
