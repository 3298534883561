import React, { useEffect } from "react";
import {
  useNavigate,
  // Link as RouterLink,
  useParams,
} from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";
import { PlayIcon } from "@heroicons/react/24/solid";
import ArchiveContainer from "components/archive/ArchiveContainer";
import ArchiveSingleEditionMultipleEpisodes from "components/archive/ArchiveSingleEditionMultipleEpisodes";
import { ContentBoxHover } from "components/common";
import useUserTracking from "utils/useTracking";

const ArchiveMultipleEditionMultipleEpisodes = ({ archive }) => {
  // console.log("ArchiveMultipleEditionMultipleEpisodes");
  const { archiveSlug, editionSlug, episodeSlug } = useParams();

  const { userTrack } = useUserTracking({ stop: true });

  const edition =
    archive.editions.find((edition) => edition.slug === editionSlug) ?? null;
  const episode =
    edition?.episodes.find((episode) => episode.slug === episodeSlug) ?? null;
  const navigate = useNavigate();

  useEffect(() => {
    if (edition === null && episode === null) {
      userTrack({
        page: archiveSlug,
      });
    }
  }, [edition, episode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {edition === null && episode === null ? (
        <ArchiveContainer archive={archive}>
          <Grid container spacing={4} justifyContent="center">
            {archive.editions.map((edition, index) => (
              <Grid
                key={`edition-${index}`}
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ContentBoxHover
                  borderRadius="0.75rem"
                  onClick={() =>
                    navigate(`/archive/${archiveSlug}/${edition.slug}`)
                  }
                >
                  <Box
                    padding={2}
                    sx={{
                      "&:hover .play-icon": {
                        opacity: 1,
                      },
                      textAlign: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h2"
                        sx={{ marginBottom: "0 !important" }}
                      >
                        {edition.title}
                      </Typography>
                    </Box>
                    <Box position="relative" marginY={1}>
                      <img
                        src={edition.image_url}
                        alt={edition.title}
                        style={{ width: "100%" }}
                      />
                      <Box
                        className="play-icon"
                        sx={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          zIndex: 1,
                          transform: "translate(-50%, -50%)",
                          opacity: 0.5,
                          transition: "all 200ms ease-in-out",
                        }}
                      >
                        <PlayIcon width={48} />
                      </Box>
                    </Box>
                    <Typography variant="h3">
                      {edition.episodes.length} episoade
                    </Typography>
                  </Box>
                </ContentBoxHover>
              </Grid>
            ))}
          </Grid>
        </ArchiveContainer>
      ) : (
        <ArchiveSingleEditionMultipleEpisodes archive={archive} />
      )}
    </>
  );
};

export default ArchiveMultipleEditionMultipleEpisodes;
