import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoggedInTemplate from "pages/templates/LoggedInTemplate";
import ArchiveInfo from "components/archive/ArchiveInfo";

const Archive = ({ contentRef }) => {
  // const user = useContext(Context);
  const { archiveSlug, editionSlug = "0", episodeSlug = "0" } = useParams();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, [archiveSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LoggedInTemplate>
      <ArchiveInfo key={`${archiveSlug}-${editionSlug}-${episodeSlug}`} />
    </LoggedInTemplate>
  );
};

export default Archive;
