import React, { useRef } from "react";
import { AuthProvider } from "react-auth-kit";
import RoutesComponent from "./Routes";
import { ThemeProvider, Box, Stack } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AppContext from "components/AppContext";
import Footer from "components/Footer";
import theme from "utils/theme";
import { QueryClient } from "@tanstack/react-query";
import {
  persistQueryClient,
  PersistQueryClientProvider,
} from "@tanstack/react-query-persist-client";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import { UtagProvider } from "hooks/useUtag";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60, // Data is fresh for 60 seconds
        refetchOnMount: true, // Always refetch on mount if data is stale
        refetchOnWindowFocus: false, // Refetch on window focus
        // refetchOnMount: "always", // true
        // networkMode: "always",
        gcTime: 1000 * 60 * 60 * 24, // 24 hours
        // refetchInterval: 5000, // Fetch the data from the API every 5 seconds
        // staleTime: 1000 * 60 * 10,
        // staleTime: 1000 * 60 * 60 * 24, // Consider the data stale after 60 seconds of no activity
        cacheTime: 1000 * 60 * 60 * 24, // Cache the data for 300 seconds
        readFromLocalStorage: true, // Read the data from local storage on refresh
        writeToLocalStorage: true, // Write the data to local storage on refresh
      },
    },
  });

  const persister = createAsyncStoragePersister({
    storage: window.localStorage,
  });

  persistQueryClient({
    queryClient,
    persister,
    maxAge: 1000 * 60 * 60 * 24, // 24 hours
    buster: "",
    hydrateOptions: undefined,
    dehydrateOptions: undefined,
    refetchOnMount: true,
  });

  const contentRef = useRef(null);

  return (
    <UtagProvider>
      <AuthProvider authName={"_auth"} authType={"cookie"}>
        <AppContext>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{ persister }}
            hydrateOptions={undefined}
            dehydrateOptions={undefined}
          >
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Stack direction="column" sx={{ minHeight: "100dvh" }}>
                <Box
                  ref={contentRef}
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      flexGrow: 1,
                    },
                    [theme.breakpoints.up("sm")]: {
                      position: "relative",
                      height: "100vh",
                      width: "100vw",
                      overflowY: "auto",
                    },
                  }}
                >
                  <RoutesComponent contentRef={contentRef} />
                </Box>
                <Footer />
              </Stack>
            </ThemeProvider>
          </PersistQueryClientProvider>
        </AppContext>
      </AuthProvider>
    </UtagProvider>
  );
}

export default App;
