import React, { useContext } from "react";
import { Box } from "@mui/material";
import appContext from "utils/context";
import theme from "utils/theme";
import isLastMonthDate from "utils/isLastMonthDate";

const NewContentLabel = ({ date, isSmall = false }) => {
  const { serverTime } = useContext(appContext);
  const isNewArticle = isLastMonthDate(date, serverTime);

  return isNewArticle ? (
    <Box
      sx={{
        position: "absolute",
        zIndex: 3,
        left: 0,
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.primary.main,
        // boxShadow: "0px 0px 20px 2px rgba(218, 204, 227, 0.5)",
        fontWeight: 500,
        lineHeight: 1,
        mixBlendMode: "plus-lighter",
        backdropFilter: "blur(4px)",
        ...(!isSmall
          ? {
              top: 16,
              padding: theme.spacing(0.75, 1, 0.75, 1.5),
              fontSize: "0.85rem",
            }
          : {
              top: 12,
              padding: theme.spacing(0.5, 0.75, 0.5, 1),
              fontSize: "0.75rem",
            }),
      }}
    >
      NOU
    </Box>
  ) : null;
};

export default NewContentLabel;
