import React from "react";
import { Typography, Box } from "@mui/material";
// import { Icon } from "components/common";
import { useAuthUser } from "react-auth-kit";
// import { Context } from "context";
import ChooseTherapeuticAreasAnimation from "components/therapeutic-areas/ChooseTherapeuticAreasAnimation";
import ChooseTherapeuticAreasAnimationMobile from "components/therapeutic-areas/ChooseTherapeuticAreasAnimationMobile";
// import ArchiveLinks from "components/archive/ArchiveLinks";

const ChooseTherapeuticAreas = () => {
  // const user = useContext(Context);
  const auth = useAuthUser();

  return (
    <Box sx={{ paddingBottom: { xs: 10, sm: 20 } }}>
      <Box textAlign="center" marginTop={6}>
        <Typography variant="h1">
          Bine ai venit,{" "}
          <span style={{ fontWeight: 700 }}>
            {auth().first_name} {auth().last_name}!
          </span>
        </Typography>
        <Typography>
          Explorează ariile terapeutice sau proiectele speciale
        </Typography>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "none" },
        }}
      >
        <ChooseTherapeuticAreasAnimationMobile />
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          paddingBottom: 10,
        }}
      >
        <ChooseTherapeuticAreasAnimation />
      </Box>
      {/* <ArchiveLinks /> */}
    </Box>
  );
};

export default ChooseTherapeuticAreas;
