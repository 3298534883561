import React, { useState, useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Grid,
  Link,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import theme from "utils/theme";
import { GetContentFooter } from "utils/api/getContent";
import appContext from "utils/context";
import { LinkText } from "components/common";

const footerTitleStyles = {
  fontSize: "0.75rem",
  lineHeight: 1.3,
  fontWeight: 700,
  marginTop: 1,
  marginBottom: 1.5,
};

const LinkFooter = styled(Link)(({ theme }) => ({
  fontSize: "0.75rem",
  color: "primary.dark",
}));

const footerLinkContainerStyles = {
  marginBottom: 0,
  fontWeight: 300,
};

const Footer = () => {
  const {
    data: footerData,
    // isError
  } = GetContentFooter();

  const { isWhiteContentPage } = useContext(appContext);

  const footerColumnStyles = {
    padding: {
      xs: theme.spacing(1, 3),
      sm: theme.spacing(1, 4),
      md: theme.spacing(1, 4, 2, 4),
    },
  };

  const footerColumnCenterStyles = {
    [theme.breakpoints.down("sm")]: {
      borderTop: "0.5px solid rgba(255, 255, 255, 0.20);",
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.20);",
    },
    [theme.breakpoints.up("sm")]: {
      borderLeft: "0.5px solid rgba(255, 255, 255, 0.20);",
      borderRight: "0.5px solid rgba(255, 255, 255, 0.20);",
    },
  };

  const FooterCol1 = () => {
    return (
      <>
        <img
          src="/assets/images/logo-AstraZeneca.svg"
          alt="AstraZeneca"
          height={24}
        />
        <Typography
          variant="body3"
          paragraph
          sx={{ marginTop: 1.25, fontWeight: 300 }}
        >
          Acest website este destinat profesioniștilor din domeniul sănătății
          din România.{" "}
          <img
            src="/assets/images/flag-romania.svg"
            alt="Romania"
            width={16}
            style={{
              margin: "0 0.5rem 0 0.25rem",
            }}
          />{" "}
          <span style={{ whiteSpace: "nowrap" }}>
            {footerData["Cod aprobare"][0].name}
          </span>
        </Typography>
      </>
    );
  };

  const FooterCol2 = () => {
    return (
      <>
        <Typography variant="body3" paragraph sx={footerTitleStyles}>
          Informații adiționale
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {footerData["Informații adiționale"].map((additionalInfo, index) => (
            <Grid item xs={12} md={6} key={`additional-info-${index}`}>
              <Typography
                variant="body3"
                paragraph
                sx={footerLinkContainerStyles}
              >
                <LinkText
                  href={additionalInfo.url}
                  target={additionalInfo.url_target}
                  rel={
                    additionalInfo.url_target === "_blank" ? "noreferrer" : null
                  }
                  underline="hover"
                >
                  {additionalInfo.name}
                </LinkText>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const FooterCol3 = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogData, setDialogData] = useState(null);

    const handleOpenDialog = (data) => {
      setDialogData(data);
      setOpenDialog(true);
    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

    const FooterDialog = () => {
      return (
        dialogData !== null && (
          <Dialog
            onClose={handleCloseDialog}
            aria-labelledby="contact-info"
            open={openDialog}
            maxWidth={false}
          >
            <DialogTitle sx={{ m: 0, pY: 2, px: 3 }} id="contact-info">
              {dialogData.section}
            </DialogTitle>
            <IconButton
              onClick={handleCloseDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <XMarkIcon
                width={24}
                style={{
                  color: theme.palette.primary.main,
                }}
              />
            </IconButton>
            <DialogContent dividers>
              <Typography sx={{ marginBottom: "0 !important" }}>
                {dialogData.name}
              </Typography>
              <Typography sx={{ marginBottom: "0 !important" }}>
                {dialogData.address}
              </Typography>
              <Typography sx={{ marginBottom: "0 !important" }}>
                <LinkText href={`tel:${dialogData.phone}`}>
                  {dialogData.phone}
                </LinkText>
              </Typography>
              <Typography>
                <LinkText
                  href={`mailto:${dialogData.email}?subject=Contact AZnextGen.ro`}
                  color="#6688A3"
                >
                  {dialogData.email}
                </LinkText>
              </Typography>
            </DialogContent>
          </Dialog>
        )
      );
    };

    return (
      <>
        <Typography variant="body3" paragraph sx={footerTitleStyles}>
          Contact
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {footerData["Contact"].map((additionalInfo, index) => (
            <Grid item xs={12} md={6} key={`contact-${index}`}>
              <Typography
                variant="body3"
                paragraph
                sx={footerLinkContainerStyles}
              >
                {additionalInfo.url ? (
                  <LinkText
                    href={additionalInfo.url}
                    target={additionalInfo.url_target}
                    rel={
                      additionalInfo.url_target === "_blank"
                        ? "noreferrer"
                        : null
                    }
                    underline="hover"
                    color="primary.dark"
                  >
                    {additionalInfo.name}
                  </LinkText>
                ) : (
                  <LinkFooter
                    onClick={() =>
                      handleOpenDialog({
                        section: additionalInfo.name,
                        ...JSON.parse(additionalInfo.data),
                      })
                    }
                    underline="hover"
                    sx={{ cursor: "pointer" }}
                  >
                    {additionalInfo.name}
                  </LinkFooter>
                )}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <FooterDialog />
      </>
    );
  };

  return footerData && Object.keys(footerData).length > 0 ? (
    <Box
      sx={{
        [theme.breakpoints.up("sm")]: {
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          zIndex: 1000,
        },
      }}
    >
      <Box
        sx={{
          borderTop: "0.5px solid rgba(255, 255, 255, 0.20)",
          background: isWhiteContentPage
            ? "linear-gradient(268deg, #670040 -0.83%, #830051 21.85%, #250017 71.86%, #690041 97.37%)"
            : "rgba(248, 248, 248, 0.05)",
          backdropFilter: "blur(16px)",
        }}
      >
        <Grid
          container
          sx={{
            fontWeight: 300,
            color: "primary.dark",
          }}
        >
          <Grid item sm={4} md={4} lg={5} sx={footerColumnStyles}>
            <FooterCol1 />
          </Grid>
          <Grid
            item
            sm={3}
            md={4}
            lg={3}
            sx={{ ...footerColumnStyles, ...footerColumnCenterStyles }}
          >
            <FooterCol2 />
          </Grid>
          <Grid item sm={5} md={4} lg={4} sx={footerColumnStyles}>
            <FooterCol3 />
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : null;
};

export default Footer;
