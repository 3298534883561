export const archiveDummy = [
  // {
  //   title: "<strong>AZ YEAR</strong> IN REVIEW",
  //   slug: "az-year-in-review",
  //   disclaimer:
  //     '<p><strong>AstraZeneca Pharma SRL</strong><br/>Str. Tipografilor nr. 1, MUSE Offices,<br/>etajele 2-3, 013714, sector 1, București<br/>Tel.: +40 21 317 60 41<br/>Email: <a href="mailto:office.romania@astrazeneca.com">office.romania@astrazeneca.com</a></p>',
  //   approval_code: "RO-18759/01.2024",
  //   editions: [
  //     {
  //       title: "Sezonul 1",
  //       slug: "sezonul-1",
  //       image_url:
  //         "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //       description:
  //         "Experții sezonului: Prof. Dr. Laura Mazilu / Prof. Dr. Gabriel Kacsó / Ș.L. Dr. Mihai Marinca",
  //       episodes: [
  //         {
  //           title: "Episodul 1",
  //           slug: "episodul-1",
  //           duration: "16 minute",
  //           approval_code: "RO-18687/01.2024",
  //           subtitle:
  //             "Clinical perspectives for PARP inhibitors combination for mCRPC",
  //           video_text: "Studiul PROpel",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_1_Ep_1_12_05_2023_Marinca_Studiul_Propel.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //         {
  //           title: "Episodul 2",
  //           slug: "episodul-2",
  //           duration: "12 minute",
  //           approval_code: "RO-18718/01.2024",
  //           subtitle: "HER2: One target, multiple tumors",
  //           video_text: "Studiul DESTINY-PanTumor-02 IA",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_1_Ep_2_12_05_2023_Mazilu_Destiny-Pantumor02.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //         {
  //           title: "Episodul 3",
  //           slug: "episodul-3",
  //           duration: "35 minute",
  //           approval_code: "RO-18919/01.2024",
  //           subtitle: "Radiotherapy as a active Player in Immuno-Oncology era",
  //           video_text: "Studiile PACIFIC-R; PACIFIC 6; DUART",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_1_Ep_3_12_11_2023_Kacso_Radioterapia.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Sezonul 2",
  //       slug: "sezonul-2",
  //       image_url:
  //         "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //       description:
  //         "Experții sezonului: Conf. Dr. Adina Croitoru / Dr. Bogdan Gafton",
  //       episodes: [
  //         {
  //           title: "Episodul 1",
  //           slug: "episodul-1",
  //           duration: "30 minute",
  //           approval_code: "RO-18791/01.2024",
  //           subtitle: "New perspectives in the treatment of gastric cancer",
  //           video_text: "Studiile MATTERHORN; DESTINY-Gastric02",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_2_Ep_1_12_11_2023_Croitoru_Matterhorn,_Destiny.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //         {
  //           title: "Episodul 2",
  //           slug: "episodul-2",
  //           duration: "28 minute",
  //           approval_code: "RO-18777/01.2024",
  //           subtitle:
  //             "Evolving hepatocellular carcinoma management in immune-oncology era",
  //           video_text: "Studiul HIMALAYA",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_2_Ep_2_12_11_2023_Gafton_Himalaya.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Sezonul 3",
  //       slug: "sezonul-3",
  //       image_url:
  //         "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //       description:
  //         "Experții sezonului: Conf. Dr. Dana Stănculeanu / Dr. Daniela Zob / Dr. Dragoș Median",
  //       episodes: [
  //         {
  //           title: "Episodul 1",
  //           slug: "episodul-1",
  //           duration: "21 minute",
  //           approval_code: "RO-18791/02.2024",
  //           subtitle:
  //             "Growing evidence for intracranial activity of novel ADCs in BM mBC",
  //           video_text: "Studiile DESTINY-Breast 01,02,03: Pooled Brain Mets",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_3_Ep_1_12_11_2023_Stanculeanu_Destiny-Breast_Pooled_Brain_Mets.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //         {
  //           title: "Episodul 2",
  //           slug: "episodul-2",
  //           duration: "33 minute",
  //           approval_code: "RO-18790/02.2024",
  //           subtitle:
  //             "Extending expectations and exploring new options in mTNBC",
  //           video_text: "Studiile BEGONIA; DESTINY-Breast 04 update",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_3_Ep_2_12_11_2023_Zob_Begonia_Destiny-Breast_Capitello.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //         {
  //           title: "Episodul 3",
  //           slug: "episodul-3",
  //           duration: "27 minute",
  //           approval_code: "RO-18788/02.2024",
  //           subtitle: "Addressing unmet needs in HR+ mBC",
  //           video_text: "Studiile TROPION-Breast01; CAPItello-291; AE",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_3_Ep_3_12_11_2023_Median_Tropion+Capitello.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Sezonul 4",
  //       slug: "sezonul-4",
  //       image_url:
  //         "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //       description:
  //         "Experții sezonului: Prof. Dr. Laura Mazilu / Ș.L. Dr. Mihai Marinca / Dr. Andrei Ungureanu",
  //       episodes: [
  //         {
  //           title: "Episodul 1",
  //           slug: "episodul-1",
  //           duration: "12 minute",
  //           approval_code: "RO-18989/02.2024",
  //           subtitle: "Ever evolving NSCLC landscape",
  //           video_text: "Studiul FLAURA2",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_4_Ep_1_12_05_2023_Mazilu_Marinca_Flaura2.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //         {
  //           title: "Episodul 2",
  //           slug: "episodul-2",
  //           duration: "14 minute",
  //           approval_code: "RO-19033/02.2024",
  //           subtitle: "New treatment strategy in resectable NSCLC",
  //           video_text: "Studiul AEGEAN",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_4_Ep_2_12_05_2023_Ungureanu_Aegean.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //         {
  //           title: "Episodul 3",
  //           slug: "episodul-3",
  //           duration: "28 minute",
  //           approval_code: "RO-19006/02.2024",
  //           subtitle: "New perspectives beyond first-line in NSCLC",
  //           video_text: "Studiul TROPION-Lung01",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_4_Ep_3_12_05_2023_Marinca_Studiul_Tropion.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //         {
  //           title: "Episodul 4",
  //           slug: "episodul-4",
  //           duration: "8 minute",
  //           approval_code: "RO-19007/02.2024",
  //           subtitle: "Optimising personalized approach in advanced NSCLC",
  //           video_text: "Studiul DESTINY-Lung02",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_4_Ep_4_12_05_2023_Ungureanu_Destiny_2.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Sezonul 5",
  //       slug: "sezonul-5",
  //       image_url:
  //         "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //       description: "",
  //       episodes: [
  //         {
  //           title: "Episodul 1",
  //           slug: "episodul-1",
  //           duration: "18 minute",
  //           approval_code: "RO-19088/02.2024",
  //           subtitle:
  //             "Redefining long-term outcomes for patients with Gynaecological Cancer",
  //           video_text: "Studiul DUO-E",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_5_Ep_1_Serban_Negru_Studiul_Duo-E.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //         {
  //           title: "Episodul 2",
  //           slug: "episodul-2",
  //           duration: "23 minute",
  //           approval_code: "RO-19090/02.2024",
  //           subtitle:
  //             "Advances in Gynaecological Cancer Research Could Change the Treatment Landscape",
  //           video_text: "PAOLA-1 tratament subsecvent; DUO-O; DUO-O IA PFS",
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Sez_5_Ep_2_Razvan_Curca_Duo-O_Duo-O_Ia_Pfs.mp4",
  //           image_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/year_in_review/Vide_preview_available.png",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: "BIBLIOTEKA DE <strong>BIOMARKERI</strong>",
  //   slug: "biblioteka-de-biomarkeri",
  //   disclaimer:
  //     '<p><strong>AstraZeneca Pharma SRL</strong><br/>Str. Tipografilor nr. 1, MUSE Offices,<br/>etajele 2-3, 013714, sector 1, București<br/>Tel.: +40 21 317 60 41<br/>Email: <a href="mailto:office.romania@astrazeneca.com">office.romania@astrazeneca.com</a></p>',
  //   approval_code: "RO-17811/10.2023",
  //   editions: [
  //     {
  //       agenda: [
  //         {
  //           type: "heading",
  //           title: "28 Noiembrie 2023",
  //         },
  //         {
  //           type: "item",
  //           hour: "16:00-16:05",
  //           title: "Welcome and Introduction",
  //         },
  //         {
  //           type: "item",
  //           hour: "16:05-16:25",
  //           title: "The National Cancer Control Plan",
  //           speaker: "Michael Schenker",
  //         },
  //         {
  //           type: "item",
  //           hour: "16:25-16:45",
  //           title:
  //             "Re-evaluating approaching to HER2 scoring in breast cancer:<br/>Guidelines, reporting & standardization",
  //           speaker: "Ovidiu Pop",
  //         },
  //         {
  //           type: "item",
  //           hour: "16:45-17:05",
  //           title:
  //             "Navigating the HER2-Low Paradigm in Breast Cancer Treatment:<br/>Overview of current guidelines approach",
  //           speaker: "Laura Mazilu",
  //         },
  //         {
  //           type: "item",
  //           hour: "17:05-17:25",
  //           title: "HER2 staining: Pre-analytical steps. Sample collection",
  //           speaker: "Mihai Stoicea",
  //         },
  //         {
  //           type: "item",
  //           hour: "17:25-17:45",
  //           title:
  //             "HER2 scoring masterclass: HER2 scoring algorithm.<br/>Assessing HER2. Reporting",
  //           speaker: "Bogdan Fetica",
  //         },
  //         {
  //           type: "item",
  //           hour: "17:45-18:00",
  //           title:
  //             "Interactive panel discussion;<br/>Clinical cases, Staining patterns vs. artefacts",
  //           speaker: "All speakers",
  //         },
  //         {
  //           type: "item",
  //           hour: "18:00-18:10",
  //           title: "Break",
  //         },
  //         {
  //           type: "item",
  //           hour: "18:10-18:30",
  //           title:
  //             "The concept of pan-tumour therapeutics: A Paradigm Shift in Diagnostic Pathology: HER2 expression actionable in many tumor types",
  //           speaker: "Diana Ionescu",
  //         },
  //         {
  //           type: "item",
  //           hour: "18:30-19:00",
  //           title:
  //             "9 common questions about BRCA Gene Mutations:<br/>Prognostic and predictive genetic biomarker",
  //           speaker: "Maria Olinca, Aleodor Andea",
  //         },
  //         {
  //           type: "item",
  //           hour: "19:00-19:05",
  //           title: "Summary and close",
  //           speaker: "All",
  //         },
  //       ],
  //       episodes: [
  //         {
  //           video_url:
  //             "https://med360.ro/be-api/assets-new/images/webcast/biblioteca_biomarkeri/Live_Recording_Bb_Express.mp4",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    title: "<strong>LABORATORUL VIRTUAL</strong>",
    slug: "laboratorul-virtual",
    disclaimer:
      '<p><strong>AstraZeneca Pharma SRL</strong><br/>Str. Tipografilor nr. 1, MUSE Offices,<br/>etajele 2-3, 013714, sector 1, București<br/>Tel.: +40 21 317 60 41<br/>Email: <a href="mailto:office.romania@astrazeneca.com">office.romania@astrazeneca.com</a></p>',
    approval_code: "RO-17590/10.2024",
    editions: [
      {
        // title: "NOIEMBRIE 2023",
        slug: "noiembrie-2023",
        episodes: [
          {
            title: "TEASER",
            slug: "teaser",
            approval_code: "RO-17672/10.2024",
            subtitle:
              "LABORATORUL VIRTUAL INCURSIUNE ÎN PATOLOGIA CANCERULUI PULMONAR",
            guest: {
              name: "Dr. Diana N. Ionescu",
              position:
                "Clinical Professor, University of British Columbia, Department of Pathology Consultant Pathologist, British Columbia Cancer Agency",
              country: "Vancouver, CANADA",
            },
            moderator: {
              name: "Dr. Silviu Alecu",
              company: "AstraZeneca",
            },
            video_url:
              "https://med360.ro/be-api/assets-new/images/webcast/laborator_virtual/laborator-teaser-2.mp4",
            image_url:
              "https://med360.ro/be-api/assets-new/images/webcast/teaser_activ.png",
          },
          {
            title: "MODUL 1",
            slug: "modul-1",
            approval_code: "RO-17705/10.2024",
            subtitle: "ETAPELE DIAGNOSTICULUI ÎN CANCERUL PULMONAR",
            guest: {
              name: "Dr. Diana N. Ionescu",
              position:
                "Clinical Professor, University of British Columbia, Department of Pathology Consultant Pathologist, British Columbia Cancer Agency",
              country: "Vancouver, CANADA",
            },
            moderator: {
              name: "Dr. Silviu Alecu",
              company: "AstraZeneca",
            },
            video_url:
              "https://med360.ro/be-api/assets-new/images/webcast/laborator_virtual/laborator-modul-1.mp4",
            image_url:
              "https://med360.ro/be-api/assets-new/images/webcast/modul_1_activ.png",
          },
          {
            title: "MODUL 2",
            slug: "modul-2",
            approval_code: "RO-17945/11.2024",
            subtitle:
              "TESTAREA BIOMARKERILOR CU ROL PREDICTIV ÎN TRATAMENTUL DE PRECIZIE - CE ÎNSEAMNA AZI?",
            guest: {
              name: "Dr. Diana N. Ionescu",
              position:
                "Clinical Professor, University of British Columbia, Department of Pathology Consultant Pathologist, British Columbia Cancer Agency",
              country: "Vancouver, CANADA",
            },
            moderator: {
              name: "Dr. Silviu Alecu",
              company: "AstraZeneca",
            },
            video_url:
              "https://med360.ro/be-api/assets-new/images/webcast/laborator_virtual/laborator-modul-2.mp4",
            image_url:
              "https://med360.ro/be-api/assets-new/images/webcast/modul_2_activ.png",
          },
          {
            title: "MODUL 3",
            slug: "modul-3",
            approval_code: "RO-18022/11.2024",
            subtitle: "TESTAREA BIOMARKERILOR – CUM ARATĂ VIITORUL?",
            guest: {
              name: "Dr. Diana N. Ionescu",
              position:
                "Clinical Professor, University of British Columbia, Department of Pathology Consultant Pathologist, British Columbia Cancer Agency",
              country: "Vancouver, CANADA",
            },
            moderator: {
              name: "Dr. Silviu Alecu",
              company: "AstraZeneca",
            },
            video_url:
              "https://med360.ro/be-api/assets-new/images/webcast/laborator_virtual/laborator-modul-3.mp4",
            image_url:
              "https://med360.ro/be-api/assets-new/images/webcast/modul_3_activ.png",
          },
        ],
      },
    ],
  },
];
