import theme from "utils/theme";

export const therapeuticAreasDesign = {
  "cardio-reno-metabolic": {
    image: "/assets/images/therapeutic-areas/cardio-reno-metabolic.svg",
    color: "linear-gradient(90deg, #208A97 0%, #6F1E9A 100%)",
    colorText: "#DA338C",
    hover: {
      borderColor: "#208A97",
      background:
        "linear-gradient(90deg, rgba(32, 138, 151, 0.30) 0%, rgba(111, 30, 154, 0.30) 100%)",
    },
    boxShadowColor: "rgba(36, 135, 152, 0.35)",
    backgroundColorDetails:
      "linear-gradient(268deg, #670040 -5.54%, #250017 19.7%, #830051 69.55%, #690041 104.97%)",
  },
  "respirator-imunologie": {
    image: "/assets/images/therapeutic-areas/respirator-imunologie.svg",
    color: "linear-gradient(90deg, #208A97 0%, #6F1E9A 100%)",
    colorText: "#86DCE6",
    hover: {
      borderColor: "#208A97",
      background:
        "linear-gradient(90deg, rgba(32, 138, 151, 0.30) 0%, rgba(111, 30, 154, 0.30) 100%)",
    },
    boxShadowColor: "rgba(36, 135, 152, 0.35)",
    backgroundColorDetails:
      "linear-gradient(268deg, #670040 -5.54%, #250017 19.7%, #830051 69.55%, #690041 104.97%)",
  },
  "oncologie-hemato-oncologie": {
    image: "/assets/images/therapeutic-areas/oncologie.svg",
    color: "linear-gradient(90deg, #208A97 0%, #6F1E9A 100%)",
    colorText: "#DCE766",
    hover: {
      borderColor: "#208A97",
      background:
        "linear-gradient(90deg, rgba(32, 138, 151, 0.30) 0%, rgba(111, 30, 154, 0.30) 100%)",
    },
    boxShadowColor: "rgba(36, 135, 152, 0.35)",
    backgroundColorDetails:
      "linear-gradient(268deg, #670040 -5.54%, #250017 19.7%, #830051 69.55%, #690041 104.97%)",
  },
  "boli-rare": {
    image: "/assets/images/therapeutic-areas/boli-rare.svg",
    color: "linear-gradient(90deg, #208A97 0%, #6F1E9A 100%)",
    colorText: "#E42F2F",
    hover: {
      borderColor: "#208A97",
      background:
        "linear-gradient(90deg, rgba(32, 138, 151, 0.30) 0%, rgba(111, 30, 154, 0.30) 100%)",
    },
    boxShadowColor: "rgba(36, 135, 152, 0.35)",
    backgroundColorDetails:
      "linear-gradient(268deg, #670040 -5.54%, #250017 19.7%, #830051 69.55%, #690041 104.97%)",
  },
};

export const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    width: "0.375rem", // Scrollbar width
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent", // Track color
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary.darker, // Thumb color
    borderRadius: "0.25rem", // Rounded corners for thumb
  },
};
