import React, { useState } from "react";
import { Box } from "@mui/material";
import JWPlayer from "@jwplayer/jwplayer-react";

const ArchiveVideoPlayer = ({ fileURL, imageURL = null }) => {
  // JWPLAYER
  const [playerId, setPlayerId] = useState("archive-video");
  // const [player, setPlayer] = useState(null);
  const handleVideoDidMountCallback = (event) => {
    // console.log("video handleVideoDidMountCallback", event);
    // setPlayer(event.player);
    setPlayerId(event.id);
  };

  return (
    <Box
      sx={{
        borderRadius: "0.75rem",
        overflow: "hidden",
      }}
    >
      <JWPlayer
        id={playerId}
        file={fileURL}
        image={imageURL}
        library="https://content.jwplatform.com/libraries/ktd5pJhX.js"
        config={{
          key: "en/aXi8ObIYnCZO0QZ8O2fioatD9DEwZkVt4ADV1MgY=",
          primary: "html5",
          hlshtml: true,
          width: "100%",
          autostart: true,
          mute: false,
          aspectratio: "16:9",
        }}
        didMountCallback={handleVideoDidMountCallback}
      />
    </Box>
  );
};

export default ArchiveVideoPlayer;
