import React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { styled } from "@mui/material/styles";

const StepperNext = ({ done = false }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: {
          xs: 18,
          sm: 24,
        },
        height: {
          xs: 18,
          sm: 24,
        },
        borderRadius: "50%",
        background:
          "linear-gradient(85deg, #250017 11.06%, #670040 80.03%, #830051 91.18%)",
      }}
    >
      <Box
        sx={{
          content: "''",
          position: "absolute",
          zIndex: 1,
          borderRadius: "50%",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          boxSizing: "border-box",
          border: {
            xs: "solid 4px #fff",
            sm: "solid 4px #fff",
          },
          mixBlendMode: "overlay",
        }}
      />
      {done && (
        <Box
          sx={{
            width: {
              xs: 12,
              sm: 16,
            },
            height: {
              xs: 12,
              sm: 16,
            },
            color: "#F8F8F8",
            opacity: 0.6,
            position: "absolute",
            top: 4,
            left: 4,
          }}
        >
          <CheckCircleIcon
            style={{
              width: "100%",
              position: "absolute",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 8,
    left: "calc(-50% + 12px)",
    right: "calc(50% + 12px)",
    [theme.breakpoints.up("sm")]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#690041",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#690041",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#690041",
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: {
    xs: 18,
    sm: 22,
  },
  alignItems: "center",
  ...(ownerState.active && {
    boxShadow: "0px 0px 0px 6px #830051",
    borderRadius: "50%",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#690041",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <StepperNext done /> : <StepperNext />}
    </QontoStepIconRoot>
  );
}

const CustomizedSteppers = (props) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={props.activeStep}
      connector={<QontoConnector />}
    >
      {props.steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomizedSteppers;
