import React, { useEffect, useState, useContext } from "react";
import {
  useNavigate,
  useLocation,
  // Link as RouterLink
} from "react-router-dom";
import {
  Button,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { Icon } from "components/common";
import { GetContentTherapeuticAreas, GetArticles } from "utils/api/getContent";
import appContext from "utils/context";

const HeaderMenu = () => {
  const { data: therapeuticAreasContent } = GetArticles();
  const { data: therapeuticAreasData } = GetContentTherapeuticAreas();

  const therapeuticAreas = therapeuticAreasData;
  // console.log(therapeuticAreas);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const therapeuticAreaSlug = location.pathname.split("/")[2] || null;
  const indicationSlug = location.pathname.split("/")[3] || null;
  // console.log(location);

  const [currentPageTitle, setCurrentPageTitle] = useState("");

  const { isWhiteContentPage } = useContext(appContext);

  useEffect(() => {
    setCurrentPageTitle(
      therapeuticAreas
        ? therapeuticAreas[therapeuticAreaSlug]?.title || "Arii terapeutice"
        : "Arii terapeutice"
    );
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    therapeuticAreasData &&
    therapeuticAreasContent && (
      <Stack
        direction="row"
        alignItems="center"
        columnGap={1}
        sx={{
          maxWidth: { xs: "calc(100vw - 470px)", lg: "calc(100vw - 670px)" },
        }}
      >
        <Box flexGrow={0}>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              fontSize: "1rem",
              lineHeight: 1.2,
              textAlign: "left",
              borderBottom: open
                ? "0.5px solid rgba(248,248,248,0.5)"
                : "0.5px solid rgba(248,248,248,0)",
              borderRadius: 0,
            }}
          >
            {currentPageTitle}{" "}
            <Box
              component={open ? ChevronUpIcon : ChevronDownIcon}
              width={20}
              style={{ verticalAlign: "middle", marginLeft: 4 }}
            />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiMenu-paper": {
                background: isWhiteContentPage
                  ? "rgba(37, 0, 23, 0.70)"
                  : "transparent",
              },
            }}
          >
            <MenuItem
              onClick={() => {
                navigate("/therapeutic-area");
                handleClose();
              }}
              selected={
                location.pathname === "/" ||
                location.pathname === "/therapeutic-area"
              }
            >
              <ListItemIcon>
                <HomeIcon width={24} />
              </ListItemIcon>
              <ListItemText>Arii terapeutice</ListItemText>
            </MenuItem>
            {Object.keys(therapeuticAreas).map((therapeuticAreaSlug) => (
              <MenuItem
                key={`menu-${therapeuticAreaSlug}`}
                onClick={() => {
                  navigate(`/therapeutic-areas/${therapeuticAreaSlug}`);
                  handleClose();
                }}
                selected={location.pathname.includes(
                  `/therapeutic-areas/${therapeuticAreaSlug}`
                )}
              >
                <ListItemIcon>
                  <Icon name="solar-planet" width={24} />
                </ListItemIcon>
                <ListItemText>
                  {therapeuticAreas[therapeuticAreaSlug].title}
                </ListItemText>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box flexGrow={1} sx={{ overflowX: "auto" }}>
          <Stack direction="row" alignItems="center" columnGap={1}>
            {therapeuticAreas[therapeuticAreaSlug]?.indications &&
              therapeuticAreas[therapeuticAreaSlug].indications.map(
                (indication) => (
                  <Box
                    key={`menu-${therapeuticAreaSlug}-${indication.slug}`}
                    sx={{ display: { xs: "none", sm: "block" } }}
                  >
                    <Button
                      sx={{
                        lineHeight: 1,
                        borderRadius: 0,
                        textAlign: "left",
                        borderBottom:
                          indicationSlug === indication.slug
                            ? "0.5px solid rgba(248,248,248,0.5)"
                            : "none",
                      }}
                      onClick={() => {
                        navigate(
                          `/therapeutic-areas/${therapeuticAreaSlug}/${indication.slug}`
                        );
                        handleClose();
                      }}
                    >
                      {indication.title}
                    </Button>
                  </Box>
                )
              )}
          </Stack>
        </Box>
      </Stack>
    )
  );
};

export default HeaderMenu;
