import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoggedInTemplate from "pages/templates/LoggedInTemplate";
import SearchInfo from "components/search/SearchInfo";

const Search = ({ contentRef }) => {
  const { tag } = useParams();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, [tag]); // eslint-disable-line react-hooks/exhaustive-deps

  const cleanedTag = tag
    ? decodeURI(tag).replace(/[^a-zA-Z0-9\s\u0080-\uFFFF-]/g, "")
    : undefined;

  return (
    <LoggedInTemplate>
      <SearchInfo key={`search-${cleanedTag}`} />
    </LoggedInTemplate>
  );
};

export default Search;
