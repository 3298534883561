import { differenceInMonths } from "date-fns";

const isLastMonthDate = (date, serverTime) => {
  if (!date) return false;

  const dateToCompare = new Date(date);

  return differenceInMonths(new Date(serverTime), dateToCompare) === 0;
};

export default isLastMonthDate;
