import React from "react";
import {
  Link,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import {
  XMarkIcon,
  ArrowTopRightOnSquareIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/outline";
import theme from "utils/theme";

const DialogExternalLink = ({ open, onClose, href }) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="link-extern-info"
      open={open}
      maxWidth="md"
    >
      <DialogTitle sx={{ m: 0, pY: 2, px: 3 }} id="link-extern-info">
        <Stack direction="row" alignItems="center" gap={1}>
          <ArrowTopRightOnSquareIcon
            width={16}
            style={{
              color: theme.palette.primary.main,
            }}
          />
          <Typography sx={{ marginBottom: "0 !important" }}>
            Link extern
          </Typography>
        </Stack>
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <XMarkIcon
          width={24}
          style={{
            color: theme.palette.primary.main,
          }}
        />
      </IconButton>
      <DialogContent dividers>
        <Typography>
          Link-ul pe care doriți să îl accesați este extern portalului
          Med360.ro. Pentru a continua, apăsați pe butonul "Continuă" sau dați
          click pe link-ul de mai jos:
        </Typography>
        <Typography>
          <Link
            href={href}
            target="_blank"
            rel="noreferrer"
            color="#f798d3"
            underline="hover"
            sx={{ wordBreak: "break-all" }}
            onClick={onClose}
          >
            {href}
          </Link>
        </Typography>
        <Box textAlign="center" marginBottom={1}>
          <Button
            variant="outlined"
            endIcon={<ArrowLongRightIcon width={20} />}
            onClick={() => {
              window.open(href, "_blank");
              onClose();
            }}
          >
            Continuă
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogExternalLink;
