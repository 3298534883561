import React from "react";
import { Box } from "@mui/material";

const ContentBoxHover = ({
  children,
  borderRadius = 0,
  onClick = () => {},
  isWhite = false,
  cursor = "pointer",
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        borderRadius,
        overflow: "hidden",
        height: isWhite ? "100%" : "auto",
        transition: "all 200ms ease-in-out",
        cursor,
        ...(isWhite
          ? {
              boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.15)",
            }
          : {}),
        "&:hover": {
          ...(isWhite
            ? {
                boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.25)",
              }
            : {
                boxShadow: "0px 0px 200px 24px rgba(37, 0, 23, 0.55)",
                backdropFilter: "blur(16px)",
              }),
          ".item-title": {
            textDecoration: "underline",
          },
        },
      }}
    >
      {children}
    </Box>
  );
};

export default ContentBoxHover;
