import React, { useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  motion,
  AnimatePresence,
  useInView,
} from "framer-motion/dist/framer-motion";
import { Typography, Box, Stack, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
} from "@heroicons/react/24/outline";
import { GetContentTherapeuticAreas, GetArticles } from "utils/api/getContent";
import theme from "utils/theme";
import { Icon, StepperNonLinear, NiceBox } from "components/common";
import useWindowAspectRatio from "utils/useWindowAspectRatio";
import useUserTracking from "utils/useTracking";
import Error404 from "pages/Error404";

const TherapeuticAreaIndication = ({
  therapeuticAreaIndication,
  therapeuticArea,
  onClick,
}) => {
  const { windowWidth } = useWindowAspectRatio();

  return (
    <>
      <NiceBox
        onClick={onClick}
        borderRadius="5.625rem"
        colors={{
          boxShadowColor: therapeuticArea.boxShadowColor,
          borderColor: therapeuticArea.hover.borderColor,
          borderImage: therapeuticArea.color,
        }}
        minHeight={windowWidth > 768 ? 200 : windowWidth > 600 ? 160 : 80}
      >
        <Box textAlign="center" sx={{ padding: theme.spacing(3, 4) }}>
          <Box
            sx={{
              marginBottom: {
                xs: 0,
                sm: 1,
                md: 2,
              },
            }}
          >
            <Icon
              name={`indicatii/white/${therapeuticAreaIndication.slug}`}
              width={windowWidth > 600 ? 40 : 28}
            />
          </Box>
          <Typography
            variant="h2"
            sx={{
              lineHeight: 1.3,
              fontSize: {
                xs: "1rem",
                sm: "1.125rem",
                lg: "1.5rem",
              },
              letterSpacing: "-0.0375rem",
              // textTransform: "uppercase",
              marginBottom: 0,
            }}
            component="span"
          >
            {therapeuticAreaIndication.title}
          </Typography>
        </Box>
      </NiceBox>
    </>
  );
};

const TherapeuticAreaDetails = ({
  therapeuticArea,
  therapeuticAreaSlug,
  topRef,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const therapeuticAreaDetails = therapeuticArea.details[currentStep - 1];
  const therapeuticAreaDetailsTopRef = useRef(null);
  const therapeuticAreaDetailsBottomRef = useRef(null);
  const inViewContent = useInView(therapeuticAreaDetailsBottomRef);
  const { windowWidth } = useWindowAspectRatio();

  return (
    <Box
      sx={{
        minHeight: { xs: "auto", lg: "100vh" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        ref={therapeuticAreaDetailsTopRef}
        position="relative"
        sx={{
          // minHeight: "70vh",
          flexGrow: 1,
          width: "100%",
          display: "flex",
          alignItems: "stretch",
          justifyContent: "center",
          borderRadius: "3rem 3rem 0rem 0rem",
          // overflow: "hidden",
          "&:before": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "3rem 3rem 0rem 0rem",
            opacity: 0.7,
            background: therapeuticArea.backgroundColorDetails,
          },
        }}
      >
        <Box
          paddingTop={5}
          marginX={6}
          sx={{
            position: "relative",
            // zIndex: 2,
            flexGrow: 1,
            width: "100%",
            maxWidth: {
              xs: `calc(100vw - ${theme.spacing(4)})`,
              lg: 1430,
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",
            paddingBottom: {
              xs: 10,
              sm: 18,
              md: 18,
              lg: 14,
            },
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Box maxWidth={300}>
              <StepperNonLinear
                activeStep={currentStep - 1}
                setCurrentStep={(index) => setCurrentStep(index)}
                steps={therapeuticArea.details.map(
                  (detail) => detail.details_tab
                )}
              />
            </Box>
            <Box
              onClick={() => {
                topRef.current.scrollIntoView({
                  behavior: "smooth",
                  inline: "start",
                });
              }}
              sx={{
                opacity: inViewContent ? 1 : 0,
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, -50%)",
                top: windowWidth > 600 ? "50%" : -20,
                transition: "all 200ms ease-in-out",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  cursor: "pointer",
                  padding: 2,
                  transition: "all 200ms ease-in-out",
                  "&:hover": {
                    "& div:first-of-type": {
                      background:
                        "linear-gradient(180deg, rgba(235, 212, 247, 0.24) 0%, rgba(235, 212, 247, 0.08) 100%)",
                    },
                    "& div:nth-of-type(2)": {
                      top: 8,
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    borderRadius: "1.5rem",
                    border: "0.5px solid rgba(255, 255, 255, 0.48)",
                    boxSizing: "border-box",
                    background:
                      "linear-gradient(180deg, rgba(235, 212, 247, 0.12) 0%, rgba(235, 212, 247, 0.04) 100%)",
                    padding: theme.spacing(1, 0.5),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "all 200ms ease-in-out",
                  }}
                >
                  <ArrowLongUpIcon width={18} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <AnimatePresence>
              {therapeuticAreaDetails.details_content_type === "full-text" && (
                <motion.div
                  key={`detail-${currentStep}`}
                  easing="easeIn"
                  initial={{
                    opacity: 0,
                    filter: "blur(4px)",
                    bottom: -10,
                    position: "absolute",
                  }}
                  animate={{
                    opacity: 1,
                    filter: "blur(0px)",
                    bottom: 0,
                    position: "relative",
                  }}
                  exit={{
                    opacity: 0,
                    filter: "blur(4px)",
                    // bottom: -20,
                    position: "absolute",
                  }}
                  transition={{
                    enter: { duration: 1000 },
                    exit: { duration: 500 },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "1.25rem",
                        md: "1.5rem",
                        lg: "2rem",
                      },
                      lineHeight: "1.5 !important",
                      // letterSpacing: "-0.05rem",
                      marginY: 6,
                    }}
                  >
                    {therapeuticAreaDetails.details_description}
                  </Typography>
                  {therapeuticAreaDetails.approval_code && (
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: 4,
                      }}
                    >
                      {therapeuticAreaDetails.approval_code}
                    </Typography>
                  )}
                </motion.div>
              )}
              {(therapeuticAreaDetails.details_content_type === "text+image" ||
                therapeuticAreaDetails.details_content_type ===
                  "image+text") && (
                <motion.div
                  key={`detail-${currentStep}`}
                  easing="easeIn"
                  initial={{
                    opacity: 0,
                    filter: "blur(4px)",
                    bottom: -10,
                    position: "absolute",
                  }}
                  animate={{
                    opacity: 1,
                    filter: "blur(0px)",
                    bottom: 0,
                    position: "relative",
                  }}
                  exit={{
                    opacity: 0,
                    filter: "blur(4px)",
                    // bottom: -20,
                    position: "absolute",
                  }}
                  transition={{
                    enter: { duration: 1000 },
                    exit: { duration: 500 },
                  }}
                >
                  <Grid container alignItems="start" spacing={6} marginY={2}>
                    <Grid
                      item
                      xs={0}
                      lg={1}
                      order={1}
                      sx={{ display: { xs: "none", lg: "block" } }}
                    ></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      lg={3}
                      order={
                        therapeuticAreaDetails.details_content_type ===
                        "text+image"
                          ? 3
                          : 2
                      }
                      textAlign={
                        therapeuticAreaDetails.details_content_type ===
                        "text+image"
                          ? "right"
                          : "left"
                      }
                    >
                      <img
                        src={`/assets/images/therapeutic-areas/${therapeuticArea.slug}-${therapeuticAreaDetails.details_content_type}.jpg`}
                        style={{
                          width: "100%",
                          maxWidth: 340,
                          borderRadius: "50%",
                        }}
                        alt={therapeuticArea.title}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      lg={7}
                      order={
                        therapeuticAreaDetails.details_content_type ===
                        "text+image"
                          ? 2
                          : 3
                      }
                      alignItems="center"
                    >
                      <Typography
                        variant="h1"
                        sx={{
                          fontSize: {
                            xs: "1.25rem",
                            md: "1.5rem",
                            lg: "2rem",
                          },
                          lineHeight: "normal",
                          fontWeight: 400,
                          marginBottom: {
                            xs: theme.spacing(2),
                            lg: theme.spacing(8),
                          },
                        }}
                      >
                        {therapeuticAreaDetails.details_title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: {
                            xs: "1.125rem",
                            md: "1.25rem",
                            lg: "1.5rem",
                          },
                          lineHeight: "1.5 !important",
                          // letterSpacing: "-0.0375rem",
                        }}
                      >
                        {therapeuticAreaDetails.details_description}
                      </Typography>
                      {therapeuticAreaDetails.approval_code && (
                        <Typography
                          variant="body2"
                          sx={{
                            marginTop: 4,
                          }}
                        >
                          {therapeuticAreaDetails.approval_code}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={0}
                      lg={1}
                      order={4}
                      sx={{ display: { xs: "none", lg: "block" } }}
                    ></Grid>
                  </Grid>
                </motion.div>
              )}
            </AnimatePresence>
          </Box>
          <Box ref={therapeuticAreaDetailsBottomRef}></Box>
        </Box>
      </Box>
    </Box>
  );
};

const TherapeuticAreaInfo = ({ contentRef }) => {
  const { therapeuticAreaSlug } = useParams();

  useUserTracking({
    page: therapeuticAreaSlug,
    therapeutic_area: therapeuticAreaSlug,
  });

  const {
    data: therapeuticAreasContent,
    // isErrorArticles,
    // isPendingArticles,
  } = GetArticles();

  const {
    data: therapeuticAreasData,
    // isError
  } = GetContentTherapeuticAreas();
  // console.log(therapeuticAreasData);
  const therapeuticAreas = therapeuticAreasData;
  const therapeuticArea = therapeuticAreas[therapeuticAreaSlug];
  const therapeuticAreaDetailsRef = useRef(null);
  const topRef = useRef(null);
  const indicationsRef = useRef(null);

  const navigate = useNavigate();

  // console.log(indicationsRef);

  // const ref = useRef(null);

  // let location = useLocation();
  // console.log(location);

  // const scroll = useScroll();
  // const { scrollYProgress } = useScroll();
  // {
  //   target: ref,
  //   offset: ["end end", "start start"],
  // }

  // const { scrollY } = useScroll();
  // const [pageScroll, setPageScroll] = useState(0);

  // useMotionValueEvent(scrollY, "change", (latest) => {
  //   console.log("Page scrollY: ", latest);
  //   setPageScroll(latest);
  // });

  const isVerticalOrientation = useMediaQuery("(orientation: portrait)");
  const { windowWidth } = useWindowAspectRatio();

  const [minHeight4Mobile, setMinHeight4Mobile] = useState("550px");

  useLayoutEffect(
    () => {
      setMinHeight4Mobile(
        `${
          (indicationsRef?.current?.offsetHeight || 400) +
          (windowWidth < 600
            ? 180
            : windowWidth < 1024
            ? 340
            : therapeuticArea?.indications.length <= 5
            ? 380
            : 560)
        }px`
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [indicationsRef.current, indicationsRef.current?.offsetHeight]
  );

  return therapeuticAreasData &&
    therapeuticAreasContent &&
    therapeuticArea !== null ? (
    therapeuticArea && Object.keys(therapeuticArea) && contentRef.current ? (
      <>
        <Box
          ref={topRef}
          sx={{
            minHeight: {
              xs: minHeight4Mobile,
              lg:
                therapeuticArea.indications.length <= 5
                  ? "calc(100vh - 61px)"
                  : minHeight4Mobile,
            },
            width: "100%",
            maxWidth: {
              xs: `calc(100vw - ${theme.spacing(4)})`,
              lg: 1430,
            },
            margin: "0 auto",
            position: "relative",
          }}
        >
          <motion.div
            easing="easeIn"
            initial={{
              // transform: "translate(-50%, calc(-15vh - 50%))",
              fontSize:
                windowWidth > 1024
                  ? "5rem"
                  : windowWidth > 768
                  ? "4rem"
                  : windowWidth > 600
                  ? "3.5rem"
                  : "1.5rem",
              width: "100%",
              top: "25vh",
            }}
            animate={{
              // transform: "translate(-35vw, calc(-28vh - 50%))",
              fontSize:
                windowWidth > 1024
                  ? "3.5rem"
                  : windowWidth > 768
                  ? "3rem"
                  : windowWidth > 600
                  ? "2.5rem"
                  : "1.25rem",
              width: "10%",
              top:
                windowWidth > 1024
                  ? 80
                  : windowWidth > 768
                  ? 70
                  : windowWidth > 600
                  ? 58
                  : 37,
            }}
            transition={{ delay: 1, duration: 2 }}
            style={{
              position: "absolute",
              // left: "50%",
              whiteSpace: "nowrap",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h1"
              style={{
                // background: therapeuticArea.color,
                // backgroundClip: "text",
                // WebkitBackgroundClip: "text",
                color: "primary.main",
                fontWeight: 700,
                lineHeight: 1.5,
                fontSize: "inherit",
                whiteSpace: "nowrap",
              }}
              component="span"
            >
              {therapeuticArea.title}
            </Typography>
          </motion.div>
          <motion.div
            easing="easeIn"
            initial={{
              width: "100%",
              height:
                windowWidth > 1024
                  ? 360
                  : windowWidth > 768
                  ? 288
                  : windowWidth > 600
                  ? 216
                  : 100,
              top: "40vh",
              // transform: "translate(-50%, calc(10vh - 50%))",
            }}
            animate={{
              width:
                windowWidth > 1024
                  ? 313
                  : windowWidth > 768
                  ? 250
                  : windowWidth > 600
                  ? 188
                  : 78,
              height:
                windowWidth > 1024
                  ? 200
                  : windowWidth > 768
                  ? 160
                  : windowWidth > 600
                  ? 120
                  : 50,
              top: 28,
              // transform: "translate(calc(35vw - 310px), calc(-28vh - 50%))",
              // scale: pageScroll >= 200 ? 0.5 : 200 / (200 + pageScroll),
              // transform: `translateX(${Math.min(pageScroll, 300)}px)`,
            }}
            transition={{ delay: 1, duration: 2 }}
            style={{
              position: "absolute",
              // zIndex: 3,
              // left: "50%",
              right: 0,
              textAlign: "center",
            }}
          >
            <img
              src={therapeuticArea.image}
              height="100%"
              alt={therapeuticArea.title}
            />
          </motion.div>
          <motion.div
            easing="easeIn"
            initial={{ top: "50vh" }}
            animate={{
              top: !isVerticalOrientation
                ? windowWidth > 1024
                  ? "40vh"
                  : windowWidth > 768
                  ? "30vh"
                  : windowWidth > 600
                  ? "20vh"
                  : 110
                : windowWidth >= 1024
                ? "20vh"
                : windowWidth > 768
                ? "20vh"
                : windowWidth > 600
                ? "20vh"
                : 110,
            }}
            transition={{ delay: 1.5, duration: 1 }}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              // left: "12vw",
              // right: "12vw",
            }}
          >
            <Stack
              ref={indicationsRef}
              justifyContent="center"
              alignItems="stretch"
              gap={2}
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  // md: isVerticalOrientation ? "column" : "row",
                  md: "row",
                  lg: "row",
                },
                flexWrap: {
                  xs: "nowrap",
                  sm: "wrap",
                  md: "wrap",
                  lg:
                    therapeuticArea.indications.length <= 5 ? "nowrap" : "wrap",
                },
              }}
            >
              {therapeuticArea.indications.length > 0 ? (
                therapeuticArea.indications.map(
                  (therapeuticAreaIndication, index) => (
                    <motion.div
                      key={`indication-${index}`}
                      easing="easeIn"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 1.5 + index * 0.25, duration: 0.25 }}
                      style={{
                        flexGrow:
                          therapeuticArea.indications.length <= 5 &&
                          windowWidth > 1200
                            ? 1
                            : windowWidth > 600
                            ? 0
                            : 1,
                        width:
                          therapeuticArea.indications.length <= 5 &&
                          windowWidth > 1200
                            ? "100%"
                            : windowWidth > 600
                            ? therapeuticArea.indications.length <= 5
                              ? "40%"
                              : "30%"
                            : "100%",
                      }}
                    >
                      <TherapeuticAreaIndication
                        therapeuticAreaIndication={therapeuticAreaIndication}
                        therapeuticArea={therapeuticArea}
                        onClick={() => {
                          navigate(
                            `/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndication.slug}`
                          );
                        }}
                      />
                    </motion.div>
                  )
                )
              ) : (
                <motion.div
                  easing="easeIn"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1.5, duration: 0.25 }}
                  style={{
                    flexGrow:
                      therapeuticArea.indications.length <= 5 &&
                      windowWidth > 1200
                        ? 1
                        : windowWidth > 600
                        ? 0
                        : 1,
                    width:
                      therapeuticArea.indications.length <= 5 &&
                      windowWidth > 1200
                        ? "100%"
                        : windowWidth > 600
                        ? therapeuticArea.indications.length <= 5
                          ? "40%"
                          : "30%"
                        : "100%",
                  }}
                >
                  <Box textAlign="center">
                    Momentan nu există conținut disponibil în această secțiune.
                  </Box>
                </motion.div>
              )}
            </Stack>
          </motion.div>
          {therapeuticArea.details.length > 0 && (
            <motion.div
              easing="easeIn"
              initial={{
                bottom: therapeuticArea.indications.length <= 5 ? 120 : -10,
                opacity: 0,
              }}
              animate={{
                bottom: therapeuticArea.indications.length <= 5 ? 140 : 10,
                opacity: 1,
              }}
              transition={{ delay: 2.5, duration: 0.5 }}
              style={{
                position: "absolute",
                left: 0,
                right: 0,
              }}
            >
              <Box
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  display: { xs: "none", lg: "flex" },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  onClick={() =>
                    therapeuticAreaDetailsRef.current.scrollIntoView({
                      behavior: "smooth",
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    padding: 2,
                    transition: "all 200ms ease-in-out",
                    "&:hover": {
                      "& div:first-of-type": {
                        background:
                          "linear-gradient(180deg, rgba(235, 212, 247, 0.24) 0%, rgba(235, 212, 247, 0.08) 100%)",
                      },
                      "& div:nth-of-type(2)": {
                        top: 8,
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "1.5rem",
                      border: "0.5px solid rgba(255, 255, 255, 0.48)",
                      boxSizing: "border-box",
                      background:
                        "linear-gradient(180deg, rgba(235, 212, 247, 0.12) 0%, rgba(235, 212, 247, 0.04) 100%)",
                      padding: theme.spacing(1, 0.5),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transition: "all 200ms ease-in-out",
                    }}
                  >
                    <ArrowLongDownIcon width={18} />
                  </Box>
                  <Box
                    paddingTop={0.5}
                    position="relative"
                    sx={{
                      transition: "all 200ms ease-in-out",
                      top: 0,
                    }}
                  >
                    <Typography component="span">misiunea noastră</Typography>
                  </Box>
                </Box>
              </Box>
            </motion.div>
          )}
          {/* </motion.div> */}
        </Box>
        <div ref={therapeuticAreaDetailsRef}>
          {therapeuticArea.details.length > 0 && (
            <TherapeuticAreaDetails
              therapeuticArea={therapeuticArea}
              therapeuticAreaSlug={therapeuticAreaSlug}
              topRef={topRef}
            />
          )}
        </div>
      </>
    ) : (
      <Error404 />
    )
  ) : null;
};

export default TherapeuticAreaInfo;
