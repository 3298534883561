import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import { useQueryClient } from "@tanstack/react-query";
import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import { StarIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import config from "utils/config";

const ArticleVotes = ({ votes = null, votesNumber = null }) => {
  const { articleSlug, contentSlug } = useParams();
  const authHeader = useAuthHeader();
  const queryClient = useQueryClient();

  const stars = 5;
  const activeVote = {
    opacity: 1,
    transition: "all 200ms ease-in-out",
  };
  const hoveredVote = {
    opacity: 1,
    cursor: "pointer",
    transition: "all 200ms ease-in-out",
  };
  const inactiveVote = {
    opacity: 0.5,
    transition: "all 200ms ease-in-out",
  };

  const [hoverVote, setHoverVotes] = useState(0);
  const [sentVote, setSentVote] = useState(null);
  const [sentVoteMessage, setSentVoteMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const setVote = (numberOfVotes) => {
    if (!sentVoteMessage && votes === null) {
      setHoverVotes(numberOfVotes);
    }
  };

  const updateLocalArticleVoteQueryData = async ({ slug, vote }) => {
    queryClient.setQueryData(["articles"], (oldData) => {
      const updatedArticles = { ...oldData }.articles.map((article) => {
        if (article.slug === slug) {
          return { ...article, user_vote: vote };
        }
        return article;
      });

      return {
        articles: updatedArticles,
        events: oldData.events,
      };
    });
    await queryClient.invalidateQueries({
      queryKey: ["articles"],
    });
  };

  const sendVote = async (numberOfVotes) => {
    if (!sentVoteMessage && votes === null) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${config.API_URL}/article-vote`,
          {
            vote: numberOfVotes,
            article: articleSlug,
            content: contentSlug,
          },
          {
            headers: {
              Authorization: authHeader(),
            },
          }
        );

        if (response.data.message === "Vote saved successfully") {
          setSentVote(numberOfVotes);
          setSentVoteMessage("Votul a fost trimis!");
          setLoading(false);
          // await new Promise((resolve) => setTimeout(resolve, 3000));
          await updateLocalArticleVoteQueryData({
            slug: articleSlug,
            vote: numberOfVotes,
          });
        } else {
          setSentVoteMessage("Votul nu a fost trimis!");
        }
      } catch (err) {
        setSentVoteMessage("Votul nu a fost trimis!");
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Stack direction="row" justifyContent="end" gap={0} sx={{ marginTop: 1 }}>
      <Stack gap={1}>
        <Stack direction="row" alignItems="center">
          {loading && <CircularProgress size={24} style={{ marginRight: 8 }} />}
          {votesNumber !== null && (
            <Box paddingTop={0.5} paddingRight={1}>
              <Typography>
                {votesNumber === 1 ? "1 vot" : votesNumber + " voturi"}
              </Typography>
            </Box>
          )}
          {[...Array(stars)].map((e, index) => (
            <Box
              key={`star-${index}`}
              sx={
                votes >= index + 1 || sentVote >= index + 1
                  ? activeVote
                  : sentVote === null && hoverVote >= index + 1
                  ? hoveredVote
                  : inactiveVote
              }
              onMouseEnter={() => setVote(index + 1)}
              onMouseLeave={() => setVote(0)}
              onClick={() => sendVote(index + 1)}
            >
              <StarIcon width={32} />
            </Box>
          ))}
        </Stack>
        {sentVoteMessage && (
          <Box sx={{ textAlign: "center" }}>
            <Typography>{sentVoteMessage}</Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default ArticleVotes;
