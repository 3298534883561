import { CONFIG_ERRORS } from "utils/config-errors";

const config = {
  API_URL:
    process.env.REACT_APP_ENV === "dev"
      ? "https://devsttm.totemsoftware.ro/be-api/api"
      : "https://med360.ro/be-api/api",
  ERRORS: CONFIG_ERRORS,
  DOCUMENTS: {
    GDPR: "https://az.box.com/s/cmidw6ixfsmgfytxu3h578to9w0d36on",
    TERMS: "https://www.astrazeneca.com/legal-notice-and-terms-of-use/ro.html",
    CONFIDENTIALITY:
      "https://www.azprivacy.astrazeneca.com/europe/romania/ro/privacy-notices.html",
  },
};

export default config;
