import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate, Link as RouterLink } from "react-router-dom";
// import { ClockIcon } from "@heroicons/react/24/outline";
import { PlayIcon } from "@heroicons/react/24/solid";
import {
  IconButtonOutlined,
  ContentBoxHover,
  NewContentLabel,
} from "components/common";
import theme from "utils/theme";
import { GetMedia } from "utils/api/getContent";
import { mediaBackgroundAPI } from "utils/media";

const VideoBox = ({
  video,
  isWhite = false,
  isHorizontal = false,
  isSmall = false,
  isSquareImage = false,
}) => {
  const { data: videoImage, isError, isPending } = GetMedia(video.image_id); // "5b0c7126-8ec2-11ee-ac3d-4e619492ee6c"

  const navigate = useNavigate();
  const articleURL = `/therapeutic-areas/${video.therapeutic_area}/${video.therapeutic_area_indication}/videos/${video.slug}`;

  return (
    <ContentBoxHover
      isWhite={isWhite}
      borderRadius="0.75rem"
      onClick={() => navigate(articleURL)}
    >
      <Stack
        direction={isHorizontal ? "row" : "column"}
        height="100%"
        alignItems="stretch"
        sx={{ position: "relative" }}
      >
        <NewContentLabel date={video.publish_date} isSmall={isSmall} />
        <Box
          sx={{
            position: "relative",
            borderRadius: "0.75rem",
            overflow: "hidden",
            // minWidth: isHorizontal ? 164 : "auto",
            // height: !isSmall ? 200 : isHorizontal ? "auto" : 130,
            minWidth: isSmall ? 104 : isHorizontal ? 214 : "100%",
            // minHeight: isSmall ? 130 : isHorizontal ? 132 : 200,
            marginBottom: isHorizontal ? 0 : 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            aspectRatio: isSquareImage ? 1 : "16/9",
            ...mediaBackgroundAPI({
              type: "video",
              item: video,
              image: videoImage,
              isPending,
              isError,
            }),
          }}
        >
          <Box>
            <IconButtonOutlined
              component={RouterLink}
              sx={{
                background:
                  "linear-gradient(85deg, rgba(40, 16, 75, 0.25) 11.06%, rgba(82, 29, 94, 0.25) 80.03%, rgba(80, 34, 103, 0.25) 91.18%)",
                boxShadow: "0px 0px 40px 1px rgba(218, 204, 227, 0.15)",
                backdropFilter: "blur(16px)",
              }}
            >
              <PlayIcon width={isSquareImage ? 18 : isSmall ? 24 : 32} />
            </IconButtonOutlined>
          </Box>
          {video.duration && (
            <Box
              sx={{
                position: "absolute",
                right: theme.spacing(isSmall ? 1 : 2),
                bottom: theme.spacing(1),
                borderRadius: "0.125rem",
                background: "rgba(12, 12, 12, 0.40)",
                paddingX: 0.5,
              }}
            >
              <Typography
                variant="body3"
                component="div"
                sx={{
                  lineHeight: "1.125rem",
                  fontWeight: 300,
                  color: !isWhite ? "inherit" : "#EEE",
                }}
              >
                {video.duration}
              </Typography>
            </Box>
          )}
        </Box>
        <Stack
          direction="column"
          flexGrow={1}
          gap={1}
          justifyContent="center"
          padding={
            isSmall
              ? theme.spacing(0.5, 1, 1, 2)
              : !isWhite
              ? 1
              : theme.spacing(0.5, 2, 1, 2)
          }
        >
          <Box flexGrow={isHorizontal ? 0 : 1}>
            <Typography
              className="item-title"
              variant={!isSmall ? "body1" : "body2"}
              sx={{
                fontWeight: !isWhite ? "inherit" : 500,
                color: !isWhite ? "inherit" : "#000",
                lineHeight: isSmall ? 1.5 : !isWhite ? "inherit" : "1.5rem",
              }}
            >
              {video.title}
            </Typography>
          </Box>
          {!isSmall && (
            <Box>
              <Typography
                variant="body3"
                color={!isWhite ? "secondary" : "primary.black"}
                component="div"
                sx={{ fontWeight: 300 }}
              >
                {video.speaker}
              </Typography>
            </Box>
          )}
          {!isSmall && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              columnGap={1}
              marginY={1}
            >
              {/* <Box
                flexGrow={0}
                sx={{
                  display: "flex",
                  width: "2.5rem",
                  height: "2.5rem",
                  padding: "0.375rem",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "0.25rem",
                  background: "rgba(255, 255, 255, 0.10)",
                  boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.15)",
                }}
              >
                <Icon
                  name={`indicatii/${!isWhite ? "white" : "gradient"}/${
                    video.therapeutic_area_indication
                  }`}
                  width={28}
                  marginTop={0}
                />
              </Box> */}
              <Box flexGrow={1}>
                <Typography
                  variant="body3"
                  color={!isWhite ? "secondary" : "secondary.dark"}
                  component="div"
                  sx={{ fontWeight: 300 }}
                >
                  {video.subtitle}
                </Typography>
              </Box>
            </Stack>
          )}
          <Typography
            variant="body3"
            color={!isWhite ? "primary.gray" : "primary.gray2"}
          >
            {video.approval_code}
          </Typography>
        </Stack>
      </Stack>
    </ContentBoxHover>
  );
};

export default VideoBox;
