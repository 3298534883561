import React from "react";
import {
  // useNavigate,
  Link as RouterLink,
  useParams,
} from "react-router-dom";
import { Typography, Box, Button, Container } from "@mui/material";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";

const ArchiveContainer = ({ archive, children }) => {
  const { archiveSlug, editionSlug } = useParams();

  return (
    <Container
      maxWidth="xl"
      sx={{ paddingTop: 2.5, paddingBottom: 20, position: "relative" }}
    >
      <Box marginBottom={5} textAlign={{ xs: "center", sm: "left" }}>
        <Button
          component={RouterLink}
          variant="text"
          startIcon={<ArrowLongLeftIcon width={20} />}
          to={
            archive.editions.length > 1 && editionSlug
              ? `/archive/${archiveSlug}`
              : `/`
          }
          sx={{ fontSize: "1rem" }}
        >
          Înapoi
        </Button>
      </Box>
      <Box marginBottom={3}>
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: "1.25rem",
              sm: "2.5rem",
              md: "3rem",
            },
            marginBottom: 0,
          }}
          dangerouslySetInnerHTML={{
            __html: archive.title,
          }}
        />
      </Box>

      {children}

      <Box
        marginTop={4}
        marginBottom={2}
        sx={{
          height: "1px",
          backgroundColor: "primary.main",
          opacity: 0.75,
          mixBlendMode: "overlay",
        }}
      />
      <Box marginY={3}>
        <Typography variant="body2">
          {archive.approval_code.split(",").map((code, index) => (
            <span key={`code-${index}`}>
              {code}
              <br />
            </span>
          ))}
        </Typography>
      </Box>
      {archive.disclaimer && (
        <Box
          marginBottom={3}
          sx={{
            a: {
              color: "primary.main",
              textDecoration: "underline",
              textDecorationColor: "rgba(255, 255, 255, 0.4)",
              transition: "all 200ms ease-in-out",
              "&:hover": {
                textDecorationColor: "rgba(255, 255, 255, 1)",
              },
            },
          }}
        >
          <Typography
            dangerouslySetInnerHTML={{
              __html: archive.disclaimer,
            }}
          />
        </Box>
      )}
    </Container>
  );
};

export default ArchiveContainer;
