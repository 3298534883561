export const redirects = {
  "/pages/asco-gu-update-februarie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-genito-urinar/articles/asco-gu-update-studiul-brcaaway-tratamentul-combinat-initial-vs-monoterapia-secventiala-imbunatateste-rezultatele-pacientului-cu-mcrpc",
  "/pages/argumente-pre-clinice-si-clinice-ce-sustin-combinatia-olaparib-plus-abiraterona-la-pacientii-comers-cu-mcrpc-martie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-genito-urinar/articles/argumente-pre-clinice-si-clinice-ce-sustin-combinatia-olaparib-plus-abiraterona-la-pacientii-all-comers-cu-mcrpc",
  "/pages/emerald-1-un-nou-standard-de-tratament-pentru-pacientii-cu-hcc-nerezecabil-eligibili-pentru-embolizare-martie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-digestiv/articles/emerald-1-un-nou-standard-de-tratament-pentru-pacientii-cu-hcc-nerezecabil-eligibili-pentru-embolizare",
  "/pages/durvalumab-plus-gemcitabina-si-cisplatin-cancerul-de-tract-biliar-avansat-studiul-topaz-1-mai-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-digestiv/articles/durvalumab-plus-gemcitabina-si-cisplatin-in-cancerul-de-tract-biliar-avansat-studiul-topaz-1",
  "/pages/durvalumab-plus-chimioterapie-dublat-rata-supravietuirii-generale-la-3-ani-la-pacientii-cu-cancer-tract-biliar-avansat-studiul-topaz-1-mai-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-digestiv/articles/durvalumab-plus-chimioterapie-a-dublat-rata-supravietuirii-generale-la-3-ani-la-pacientii-cu-cancer-tract-biliar-avansat-in-studiul-topaz-1",
  "/pages/enhertu-demonstrated-statistically-significant-clinically-meaningful-improvement-progression-free-survival-hr-positive-mai-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-mamar/articles/enhertu-demonstrated-statistically-significant-and-clinically-meaningful-improvement-in-progression-free-survival-in-hr-positive-her2-low-metastatic-breast-cancer-following-one-or-more-lines-of-endocrine-therapy-in-destiny-breast06-phase-iii-trial",
  "/pages/primele-beneficii-de-os-ls-sclc-studiul-adriatic-mai-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-pulmonar/articles/primele-beneficii-de-os-in-ls-sclc-studiul-adriatic",
  "/pages/eau24-abordari-practice-legate-de-shared-decision-making-tratamentul-pacientilor-cu-cancer-de-prostata-metastazat-mai-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-genito-urinar/articles/eau24-abordari-practice-legate-de-shared-decision-making-in-tratamentul-pacientilor-cu-cancer-de-prostata-metastazat",
  "/pages/osimertinib-primul-si-singurul-inhibitor-egfr-si-tratamentul-tintit-care-prezinta-beneficii-nsclc-nerezecabil-stadiul-iii-iulie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-pulmonar/articles/osimertinib-primul-si-singurul-inhibitor-egfr-si-tratamentul-tintit-care-prezinta-beneficii-in-nsclc-nerezecabil-stadiul-iii",
  "/pages/truqap-plus-faslodex-approved-in-the-eu-for-patients-with-advanced-er-positive-breast-cancer-iulie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-mamar/articles/truqap-plus-faslodex-approved-in-the-eu-for-patients-with-advanced-er-positive-breast-cancer",
  "/pages/supravietuirea-pe-termen-lung-in-cancerul-hepatocelular-in-era-imunoterapiei-iulie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-digestiv/articles/supravietuirea-pe-termen-lung-in-cancerul-hepatocelular-in-era-imunoterapiei",
  "/pages/tropion-lung-01-datopotamab-deruxtecan-primul-adc-care-arata-eficacitate-vs-docetaxel-in-liniile-subsecvente-a-mnsclc-iulie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-pulmonar/articles/tropion-lung-01-datopotamab-deruxtecan-primul-adc-care-arata-eficacitate-vs-docetaxel-in-liniile-subsecvente-a-mnsclc",
  "/pages/perspective-clinice-in-mcrpc-incorporarea-terapiei-bazata-pe-combinatia-parpi-in-practica-clinica-iulie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-genito-urinar/articles/perspective-clinice-in-mcrpc-incorporarea-terapiei-bazata-pe-combinatia-parpi-in-practica-clinica",
  "/pages/despre-imbunatatirea-supravietuirii-generale-in-btc-avansat-septembrie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-digestiv/articles/despre-imbunatatirea-supravietuirii-generale-in-btc-avansat",
  "/pages/conjugatii-anticorp-medicament-racheta-biologica-in-terapia-tintita-septembrie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/biomarkeri/articles/conjugatii-anticorp-medicament-racheta-biologica-in-terapia-tintita",
  "/pages/october-liver-cancer-awareness-month-octombrie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-digestiv/articles/october-liver-cancer-awareness-month-octombrie-2024",
  "/pages/io-si-supravietuirea-pe-termen-lung-in-hcc-nerezecabil-avansat-octombrie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-digestiv/articles/io-si-supravietuirea-pe-termen-lung-in-hcc-nerezecabil-avansat",
  "/pages/esmo-2024-update-imunoterapia-perioperatorie-in-cancerul-de-vezica-urinara-musculo-invaziv-octombrie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-genito-urinar/articles/esmo-2024-update-imunoterapia-perioperatorie-in-cancerul-de-vezica-urinara-musculo-invaziv",
  "/pages/inhibitorii-parp-in-cancerul-de-san-progrese-inregistrate-si-sperante-viitoare-octombrie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-mamar/articles/inhibitorii-parp-in-cancerul-de-san-progrese-inregistrate-si-sperante-viitoare",
  "/pages/solutii-terapeutice-pentru-pacienti-egfrm-linia-doua-studiul-savanah-decembrie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-pulmonar/articles/solutii-terapeutice-pentru-pacienti-egfrm-in-linia-doua-studiul-savanah",
  "/pages/rolul-terapiei-cu-trastuzumab-deruxtecan-managementul-timpuriu-al-pacientilor-cu-neoplasm-mamar-metastatic-cu-her2-low-si-ultra-low-decembrie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-mamar/articles/rolul-terapiei-cu-trastuzumab-deruxtecan-in-managementul-timpuriu-al-pacientilor-cu-neoplasm-mamar-metastatic-cu-her2-low-si-ultra-low",
  "/pages/esmo-2024-studiul-volga-ctdna-clearance-biomarker-al-raspunsului-patologic-la-pacientii-mibc-ineligibili-la-tratamentul-cu-cisplatina-decembrie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-genito-urinar/articles/esmo-2024-studiul-volga-ctdna-clearance-biomarker-al-raspunsului-patologic-la-pacientii-mibc-ineligibili-la-tratamentul-cu-cisplatina",
  "/pages/noiembrie-este-despre-cancerul-gastric-decembrie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-digestiv/articles/noiembrie-este-despre-cancerul-gastric",
  "/pages/articol-recomandat-noiembrie-2023":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/articol-recomandat-noiembrie-2023",
  "/pages/de-prin-ghiduri-adunate-noiembrie-2023":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/de-prin-ghiduri-adunate-noiembrie-2023",
  "/pages/hot-news-noiembrie-2023":
    " /therapeutic-areas/respirator-imunologie/astm-sever/articles/astm-hot-news-noiembrie-2023",
  "/pages/puncte-de-vedere-noiembrie-2023":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/puncte-de-vedere-noiembrie-2023",
  "/pages/articol-recomandat-decembrie-2023":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/articol-recomandat-decembrie-2023",
  "/pages/de-prin-ghiduri-adunate-decembrie-2023":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/de-prin-ghiduri-adunate-decembrie-2023",
  "/pages/hot-news-decembrie-2023":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/hot-news-decembrie-2023",
  "/pages/puncte-de-vedere-decembrie-2023":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/puncte-de-vedere-decembrie-2023",
  "/pages/puncte-de-vedere-februarie-2024":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/puncte-de-vedere-februarie-2024",
  "/pages/de-prin-ghiduri-adunate-februarie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/de-prin-ghiduri-adunate-februarie-2024",
  "/pages/articol-recomandat-februarie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/articol-recomandat-februarie-2024",
  "/pages/hot-news-februarie-2024":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/hot-news-februarie-2024",
  "/pages/puncte-de-vedere-martie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/puncte-de-vedere-martie-2024",
  "/pages/articol-recomandat-martie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/articol-recomandat-martie-2024",
  "/pages/de-prin-ghiduri-adunate-martie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/de-prin-ghiduri-adunate-martie-2024",
  "/pages/hot-news-martie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/astm-hot-news-martie-2024",
  "/pages/puncte-de-vedere-aprilie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/puncte-de-vedere-aprilie-2024",
  "/pages/articol-recomandat-aprilie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/articol-recomandat-aprilie-2024",
  "/pages/de-prin-ghiduri-adunate-aprilie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/de-prin-ghiduri-adunate-aprilie-2024",
  "/pages/hot-news-aprilie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/astm-hot-news-aprilie-2024",
  "/pages/puncte-de-vedere-mai-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/puncte-de-vedere-mai-2024",
  "/pages/articol-recomandat-mai-2024":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/articol-recomandat-mai-2024",
  "/pages/de-prin-ghiduri-adunate-mai-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/de-prin-ghiduri-adunate-mai-2024",
  "/pages/hot-news-mai-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/astm-hot-news-mai-2024",
  "/pages/articol-recomandat-iulie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/astm-articol-recomandat-iunie-2024",
  "/pages/de-prin-ghiduri-adunate-iulie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/de-prin-ghiduri-adunate-iunie-2024",
  "/pages/hot-news-iulie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/hot-news-iunie-2024",
  "/pages/puncte-de-vedere-august-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/puncte-de-vedere-august-2024",
  "/pages/articol-recomandat-august-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/articol-recomandat-august-2024",
  "/pages/hot-news-august-2024":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/bpoc-hot-news-august-2024",
  "/pages/de-prin-ghiduri-adunate-septembrie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/de-prin-ghiduri-adunate-septembrie-2024",
  "/pages/articol-recomandat-septembrie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/articol-recomandat-septembrie-2024",
  "/pages/hot-news-septembrie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/astm-hot-news-septembrie-2024",
  "/pages/puncte-de-vedere-septembrie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/puncte-de-vedere-septembrie-2024",
  "/pages/de-prin-ghiduri-adunate-octombrie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/de-prin-ghiduri-adunate-octombrie-2024",
  "/pages/articol-recomandat-octombrie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/articol-recomandat-octombrie-2024",
  "/pages/puncte-de-vedere-octombrie-2024":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/puncte-de-vedere-octombrie-2024",
  "/pages/hot-news-octombrie-2024":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/bpoc-hot-news-octombrie-2024",
  "/pages/de-prin-ghiduri-adunate-noiembrie-2024":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/articol-recomandat-noiembrie-2024",
  "/pages/articol-recomandat-noiembrie-2024":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/de-prin-ghiduri-adunate-noiembrie-2024",
  "/pages/puncte-de-vedere-noiembrie-2024":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/hot-news-noiembrie-2024",
  "/pages/hot-news-noiembrie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/puncte-de-vedere-noiembrie-2024",
  "/pages/hot-news-decembrie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/hot-news-decembrie-2024",
  "/pages/de-prin-ghiduri-adunate-decembrie-2024":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/de-prin-ghiduri-adunate-decembrie-2024",
  "/pages/puncte-de-vedere-decembrie-2024":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/puncte-de-vedere-decembrie-2024",
  "/pages/ash-2023-elevate-tn-spotlight-decembrie-2023":
    "/therapeutic-areas/oncologie-hemato-oncologie/hemato-oncologie/articles/ash-2023-elevate-tn-spotlight",
  "/pages/interventiile-chirurgicale-leucemia-limfatica-cronica-ianuarie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/hemato-oncologie/articles/interventiile-chirurgicale-in-leucemia-limfatica-cronica",
  "/pages/acalabrutinib-terapie-de-1l-la-pacientii-cu-limfom-cu-celule-de-manta-eha-2024-echo-late-breaking-abstract-iulie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/hemato-oncologie/articles/acalabrutinib-terapie-de-1l-la-pacientii-cu-limfom-cu-celule-de-manta",
  "/pages/malignitatile-secundare-la-pacientii-cu-leucemie-limfocitara-cronica-octombrie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/hemato-oncologie/articles/malignitatile-secundare-la-pacientii-cu-leucemie-limfocitara-cronica",
  "/pages/abordarea-clinica-limfomul-cu-celule-de-manta-mcl-particularitati-evolutive-si-provocari-noiembrie-2024":
    "/therapeutic-areas/oncologie-hemato-oncologie/hemato-oncologie/articles/abordarea-clinica-in-limfomul-cu-celule-de-manta-mcl-particularitati-evolutive-si-provocari",
  "/pages/intre-tipic-si-atipic-sindromul-hemolitic-uremic-la-copil-iunie-2024":
    "/therapeutic-areas/boli-rare/sindromul-hemolitic-uremic-atipic-shua/articles/intre-tipic-si-atipic-sindromul-hemolitic-uremic-la-copil-iunie-2024",
  "/pages/studiul-reason-date-din-practica-real-world-es-sclc-ianuarie-2025":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-pulmonar/articles/studiul-reason-date-din-practica-real-world-in-es-sclc",
  "/pages/date-din-practica-clinica-curenta-privind-spectrul-de-pozitivitate-her2-la-pacientii-cu-cancer-mamar-din-romania-spectrum-ianuarie-2025":
    "/therapeutic-areas/oncologie-hemato-oncologie/cancer-mamar/articles/date-din-practica-clinica-curenta-privind-spectrul-de-pozitivitate-her2-la-pacientii-cu-cancer-mamar-din-romania-spectrum",
  // "/review": "/archive/az-year-in-review",
  "/webcast/laborator-virtual-modul-2-noiembrie-2023":
    "/archive/laboratorul-virtual/noiembrie-2023/teaser",
  "/pages/puncte-de-vedere-februarie-2025":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/astm-puncte-de-vedere-februarie-2025",
  "/pages/de-prin-ghiduri-adunate-februarie-2025":
    "/therapeutic-areas/respirator-imunologie/bpoc/articles/de-prin-ghiduri-adunate-februarie-2025",
  "/pages/hot-news-februarie-2025":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/astm-hot-news-februarie-2025",
  "/pages/articol-recomandat-februarie-2025":
    "/therapeutic-areas/respirator-imunologie/astm-sever/articles/articol-recomandat-februarie-2025",
};
