import React, { useState, useRef, useEffect } from "react";
import { Typography, Box, Stack, Grid } from "@mui/material";
import { scrollbarStyles } from "utils/config-design";
import ArchiveContainer from "components/archive/ArchiveContainer";
import ArchiveVideoPlayer from "components/archive/ArchiveVideoPlayer";
import useUserTracking from "utils/useTracking";

const ArchiveSingleEditionSingleEpisode = ({ archive }) => {
  useUserTracking({
    page: archive.slug,
  });

  const videoContainerRef = useRef(null);
  const [agendaHeight, setAgendaHeight] = useState(300);

  useEffect(() => {
    const handleResize = () => {
      if (videoContainerRef.current) {
        setAgendaHeight(videoContainerRef?.current?.clientHeight - 80);
      }
    };
    handleResize();
    window.setTimeout(() => {
      handleResize();
    }, 500);

    // Add event listener on component mount
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [videoContainerRef]);

  return (
    <ArchiveContainer archive={archive}>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={8}>
          <Box height="100%" ref={videoContainerRef}>
            <ArchiveVideoPlayer
              fileURL={archive.editions[0].episodes[0].video_url}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4} height={{ xs: 400, sm: "auto" }}>
          <Stack direction="column" height="100%">
            <Box
              flexGrow={1}
              sx={{
                borderRadius: "0.75rem",
                background: "rgba(0, 0, 0, 0.35)",
              }}
            >
              <Stack
                direction="column"
                height="100%"
                gap={2}
                sx={{
                  paddingY: 2,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      paddingLeft: 3,
                    }}
                  >
                    AGENDA
                  </Typography>
                </Box>
                <Box flexGrow={1}>
                  <Box
                    sx={{
                      width: "100%",
                      // height: player?.getHeight() - 105 || 300,
                      height: {
                        xs: 300,
                        sm: agendaHeight,
                      },
                      overflowY: "auto",
                      ...scrollbarStyles,
                    }}
                  >
                    <Stack spacing={1.5}>
                      {archive.editions[0].agenda.map((agendaItem, index) => (
                        <Box
                          key={`agenda-item-${index}`}
                          sx={{
                            paddingX: 3,
                            ...(agendaItem.type === "heading"
                              ? {
                                  background: "rgba(255, 255, 255, 0.15)",
                                  paddingY: 1.25,
                                }
                              : {}),
                          }}
                        >
                          <Stack direction="row" alignItems="start" spacing={2}>
                            {agendaItem.type === "item" && (
                              <Box sx={{ width: 80, minWidth: 80 }}>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 400 }}
                                >
                                  {agendaItem.hour}
                                </Typography>
                              </Box>
                            )}
                            <Stack direction="column" gap={0.5}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 500,
                                  marginBottom: "0 !important",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: agendaItem.title,
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 400, fontStyle: "italic" }}
                              >
                                {agendaItem.speaker}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </ArchiveContainer>
  );
};

export default ArchiveSingleEditionSingleEpisode;
