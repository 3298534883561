import React, {
  useEffect,
  useState,
  // useContext
} from "react";
import {
  useNavigate,
  useLocation,
  // Link as RouterLink
} from "react-router-dom";
import {
  Box,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
} from "@mui/material";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { Icon } from "components/common";
import { GetContentTherapeuticAreas } from "utils/api/getContent";

const HeaderMenu = ({ closeDrawer = () => {} }) => {
  const { data: therapeuticAreasData } = GetContentTherapeuticAreas();
  const therapeuticAreas = therapeuticAreasData;

  const navigate = useNavigate();
  const location = useLocation();
  const therapeuticAreaSlug = location.pathname.split("/")[2] || null;
  const indicationSlug = location.pathname.split("/")[3] || null;
  // console.log(location);

  const [currentPageSlug, setCurrentPageSlug] = useState("");

  // const { isWhiteContentPage } = useContext(appContext);

  useEffect(() => {
    setCurrentPageSlug(therapeuticAreas[therapeuticAreaSlug]?.slug || "");
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const [open, setOpen] = useState(null);

  const handleClick = (area) => {
    setOpen((prev) => ({ ...prev, [area]: !prev[area] }));
  };

  useEffect(() => {
    // console.log("therapeuticAreasData", therapeuticAreasData);
    if (Object.keys(therapeuticAreasData).length && open === null) {
      let openObj = {};
      Object.keys(therapeuticAreas).forEach((item) => {
        openObj[item] = item === therapeuticAreaSlug ? true : false;
      });
      // console.log("openObj", openObj);
      setOpen(openObj);
    }
  }, [therapeuticAreasData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    therapeuticAreasData &&
    open !== null && (
      <Box marginX={-1.5}>
        <List component="nav">
          <ListItemButton
            selected={currentPageSlug === ""}
            onClick={() => {
              navigate("/therapeutic-area");
              closeDrawer();
            }}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <HomeIcon width={16} />
            </ListItemIcon>
            <ListItemText sx={{ fontSize: "1rem" }} primary="Acasă" />
          </ListItemButton>

          {Object.keys(therapeuticAreas).map((therapeuticAreaSlug) => (
            <Box key={`menu-${therapeuticAreaSlug}`}>
              <ListItemButton
                selected={
                  currentPageSlug === therapeuticAreaSlug &&
                  (indicationSlug === null || indicationSlug === "")
                }
              >
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <Icon name="solar-planet" width={16} marginTop={0} />
                  {/* <img
                    src={therapeuticAreas[therapeuticAreaSlug].image}
                    height={14}
                    alt={therapeuticAreas[therapeuticAreaSlug].title}
                  /> */}
                </ListItemIcon>
                <ListItemText
                  sx={{ fontSize: "1rem" }}
                  primary={therapeuticAreas[therapeuticAreaSlug].title}
                  onClick={() => {
                    navigate(`/therapeutic-areas/${therapeuticAreaSlug}`);
                    closeDrawer();
                  }}
                />
                {therapeuticAreas[therapeuticAreaSlug]?.indications.length >
                  0 &&
                  (open[therapeuticAreaSlug] ? (
                    <ChevronUpIcon
                      width={18}
                      onClick={() => handleClick(therapeuticAreaSlug)}
                    />
                  ) : (
                    <ChevronDownIcon
                      width={18}
                      onClick={() => handleClick(therapeuticAreaSlug)}
                    />
                  ))}
              </ListItemButton>
              <Collapse
                in={open[therapeuticAreaSlug]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {therapeuticAreas[therapeuticAreaSlug]?.indications &&
                    therapeuticAreas[therapeuticAreaSlug].indications.map(
                      (indication) => (
                        <ListItemButton
                          key={`menu-${therapeuticAreaSlug}-${indication.slug}`}
                          selected={indicationSlug === indication.slug}
                          onClick={() => {
                            navigate(
                              `/therapeutic-areas/${therapeuticAreaSlug}/${indication.slug}`
                            );
                            closeDrawer();
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: 30 }}></ListItemIcon>
                          <ListItemText primary={indication.title} />
                        </ListItemButton>
                      )
                    )}
                </List>
              </Collapse>
            </Box>
          ))}
        </List>
      </Box>
    )
  );
};

export default HeaderMenu;
