import React, { useEffect, useContext } from "react";
import {
  // useNavigate,
  Link as RouterLink,
  useParams,
} from "react-router-dom";
import { Container, Button, Typography, Box, Grid, Stack } from "@mui/material";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import { SearchBox, ArticleBox } from "components/common";
import { GetArticles, GetContentTherapeuticAreas } from "utils/api/getContent";
import appContext from "utils/context";
import useUserTracking from "utils/useTracking";
import theme from "utils/theme";

const SearchInfo = () => {
  const { tag } = useParams();
  const cleanedTag = tag
    ? decodeURI(tag).replace(/[^a-zA-Z0-9\s\u0080-\uFFFF-]/g, "")
    : undefined;

  // decodeURI(tag).replace(/[^a-zA-Z0-9\săĂîÎâÂșȘțȚşŞţŢÇçÑñÃãČčŠšÄäÖöÈèÒòÉéÁ]/g,"")

  useUserTracking({
    page: "search",
    content_type: cleanedTag,
  });

  // CONTENT
  const {
    data: allArticles,
    // isErrorArticles,
    // isPendingArticles,
  } = GetArticles();

  const {
    data: therapeuticAreasData,
    // isError,
    // isPending,
  } = GetContentTherapeuticAreas();

  console.log("therapeuticAreasData", therapeuticAreasData);

  const results =
    allArticles?.articles.filter((article) =>
      article?.tags
        .map((tag) => tag.toLowerCase())
        .includes(cleanedTag.toLowerCase())
    ) || [];

  console.log("results", results);

  const groupedResults = results.reduce((acc, article) => {
    const { therapeutic_area, therapeutic_area_indication } = article;

    if (!acc[therapeutic_area]) {
      acc[therapeutic_area] = {};
    }

    if (!acc[therapeutic_area][therapeutic_area_indication]) {
      acc[therapeutic_area][therapeutic_area_indication] = [];
    }

    acc[therapeutic_area][therapeutic_area_indication].push(article);

    return acc;
  }, {});

  const { setIsWhiteContentPage } = useContext(appContext);
  useEffect(() => {
    setIsWhiteContentPage(true);

    return () => {
      setIsWhiteContentPage(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    allArticles &&
    therapeuticAreasData && (
      <Box
        sx={{
          background:
            "linear-gradient(180deg, transparent 379px, rgba(255,255,255,1) 379px)",
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "start",
        }}
      >
        <Box
          sx={{
            height: { xs: 160, sm: 180 },
            position: "relative",
            // background: `url(/assets/images/products/banners/search.png) transparent no-repeat center`,
            // backgroundSize: "cover",
          }}
        >
          <video
            muted
            loop
            autoPlay
            playsInline
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center right",
            }}
          >
            <source
              src={`/assets/images/products/banners/search.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Box>
        <Container maxWidth="xl">
          <Box paddingY={3}>
            <Box>
              <Button
                component={RouterLink}
                variant="text"
                startIcon={<ArrowLongLeftIcon width={20} />}
                to={-1}
                sx={{ fontSize: "0.875rem", color: "primary.main" }}
              >
                Înapoi
              </Button>
            </Box>
            <Stack direction="row" alignItems="center" gap={3}>
              <Typography variant="h1" sx={{ fontWeight: 700 }}>
                Căutare
              </Typography>
              <SearchBox maxWidth={240} />
            </Stack>
          </Box>
        </Container>
        <Box
          sx={{
            background: "rgba(255,255,255,1)",
            paddingTop: 2,
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              position: "relative",
              paddingBottom: {
                xs: "20px",
                sm: "140px",
                md: "120px",
                lg: "100px",
              },
              height: "100%",
              minHeight: "100%",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              justifyContent: "center",
            }}
          >
            <Box paddingY={4}>
              {results.length === 0 && (
                <Typography sx={{ color: theme.palette.primary.black }}>
                  Niciun rezultat pentru{" "}
                  <span style={{ fontWeight: 500 }}>{cleanedTag}</span>.
                </Typography>
              )}
              {Object.entries(groupedResults).map(
                ([therapeutic_area, indications]) => (
                  <Box key={therapeutic_area} marginBottom={6}>
                    <Typography
                      variant="h2"
                      sx={{ color: "secondary.dark", marginBottom: 3 }}
                    >
                      {therapeuticAreasData[therapeutic_area].title}
                    </Typography>

                    {Object.entries(indications).map(
                      ([therapeutic_area_indication, articles]) => (
                        <Box key={therapeutic_area_indication} marginBottom={3}>
                          <Typography
                            variant="h3"
                            sx={{ color: "secondary.dark" }}
                            gutterBottom
                          >
                            {
                              therapeuticAreasData[
                                therapeutic_area
                              ].indications.find(
                                (indication) =>
                                  indication.slug ===
                                  therapeutic_area_indication
                              ).title
                            }
                          </Typography>

                          <Grid container spacing={4}>
                            {articles.map((article, index) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                key={`article-${index}`}
                              >
                                <ArticleBox
                                  article={article}
                                  isWhite
                                  isHorizontal
                                  isSmall
                                  isSquareImage
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      )
                    )}
                  </Box>
                )
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    )
  );
};

export default SearchInfo;
