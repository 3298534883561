import * as DOMPurify from "dompurify";

export const articleContentSanitized = (content) => {
  return DOMPurify.sanitize(content, {
    ADD_TAGS: ["iframe", "script"],
    ADD_ATTR: [
      "allow",
      "allowfullscreen",
      "frameborder",
      "scrolling",
      "target",
    ],
  });
};
