import React from "react";

const LogoMed360 = ({ width = "auto", height = 24, maxWidth = "auto" }) => {
  return (
    <img
      src="/assets/images/logo-med360.svg"
      alt="med360"
      width={width}
      height={height}
      style={{
        verticalAlign: "middle",
        maxWidth,
        // alignSelf: "center",
      }}
    />
  );
};

export default LogoMed360;
