// import React from "react";
// import { Box } from "@mui/material";

const HomeGraphics = () => {
  return null;
  // return (
  //   <Box sx={{ position: "relative", marginBottom: 2, textAlign: "center" }}>
  //     <img
  //       src="/assets/images/home-graphics.svg"
  //       alt="Planeta"
  //       style={{ width: "100%", maxWidth: "35%" }}
  //     />
  //   </Box>
  // );
};

export default HomeGraphics;
